import React, {
  useRef,
  useState,
  useEffect,
  createContext,
  useContext,
} from 'react';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Video from 'yet-another-react-lightbox/plugins/video';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import ImageModal from '../components/Common/imagemodel';
import Sidebar from '../components/Common/Sidebar';
import Header from '../components/Common/Header';
import bike_img from '../images/bike_img.png';
import placeholder_img from '../images/camera_img.svg';
import SurveyForm from '../components/Service/Surveyform';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import './print.css';
import { slidesFormatter } from '../components/Common/slidesFormatter.js';
const profile = localStorage.getItem('profile');
const company_name = localStorage.getItem('company_name');

function DisplayAssembling() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showVinModal, setShowVinModal] = useState(false);
  const [showspeedoModal, setShowspeedoModal] = useState(false);
  const [showfront_45Modal, setShowfront_45Modal] = useState(false);
  const [showrear_45Modal, setShowrear_45Modal] = useState(false);
  const [showLeftViewModal, setShowLeftViewModal] = useState(false);
  const [showRightViewModal, setShowRightViewModal] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [selectedCards, setSelectedCards] = useState([]);
  const data = localStorage.getItem('vinnData');
  const [showModal, setShowModal] = useState(false);
  const profile_id = localStorage.getItem('profile_id');
  const parsed_data = JSON.parse(data);
  const db_data = parsed_data?.data;
  const vin = localStorage.getItem('vinnData');
  const product_id = db_data?.id;
  const dateStr = db_data?.date_of_manufacture;
  const dateObj = new Date(dateStr);
  const year = dateObj.getFullYear();
  // console.log("db_data+++++>> ",db_data)
  const image_url = `${db_data?.image}`;
  // const [openSidebar, setOpenSidebar] = useState(false)
  const [comment, setComment] = useState('');
  const [selectedFile5, setSelectedFile5] = useState(null);
  const [selectedFile6, setSelectedFile6] = useState(null);
  const [loading, setLoading] = useState(true);
  const [prevtime, setprevtime] = useState('');
  const [responseData, setResponseData] = useState(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [selectedFile4, setSelectedFile4] = useState(null);

  const [selectedItems, setSelectedItems] = useState([]);

  const [spareParts, setSpareParts] = useState([]);
  const [files, setFiles] = useState([null, null, null, null]);
  const [elapsedTime, setElapsedTime] = useState(0);

  const timers = useRef([]);
  const [services, setServices] = useState([]);
  const [fillvalue, setfillValue] = useState({});
  const [valvalue, setvalValue] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentItem = services[currentIndex];
  ///////
  const [isYesChecked, setIsYesChecked] = useState({});
  const [isChecked, setIsChecked] = useState({});
  const [textValue, setTextValue] = useState({});
  const [available, setAvailable] = useState(false);
  const profileId = +localStorage.getItem('profile_id');
  const [mentainence, setMentainance] = useState({});
  const timerRefm = useRef(0);
  const timerRef = useRef([]);
  const [timer, setTimer] = useState({});
  const [disabledIndices, setDisabledIndices] = useState([]);
  const [timerValue, setTimerValue] = useState(0);
  const [timerRunning, setTimerRunning] = useState(false);

  const fileInputRefaddspeedometer = useRef(null);
  const fileInputRefaddvincode = useRef(null);
  const fileInputReffrontview = useRef(null);
  const fileInputRefrareview = useRef(null);

  const fileInputReferrordetail = useRef(null);
  const fileInputRef6 = useRef(null);
  const [temptime, setTemp] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  //   const [hasAssembling, setHasAssembling] = useState({});

  const [newdata, setnewData] = useState({});

  // Create a ref to the component that needs to be printed
  const componentRef = useRef();

  // Define the function to handle printing
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // Retrieve and parse data from localStorage
  const productId = db_data?.id;
  const [hasAssembling, setHasAssembling] = useState(null);
  useEffect(() => {
    const fetchAssemblingData = async () => {
      try {
        if (db_data?.id) {
          const assemblingResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/api/assembling/product/${db_data.id}/`
          );
          const assemblingData = await assemblingResponse.json();

          console.log('Assembling Data:', assemblingData);

          const hasAssemblingData = assemblingData.length > 0;
          setHasAssembling(hasAssemblingData ? assemblingData[0] : null);

          // Log the data right after setting it
          console.log(
            'Stored Assembling Data:',
            hasAssemblingData ? assemblingData[0] : 'No data'
          );
        } else {
          console.error('No ID provided in db_data');
        }
      } catch (error) {
        console.error('Error fetching assembling data:', error);
      }
    };

    fetchAssemblingData();
  }, [db_data?.id]);

  // const getProductImages = ()=>{
  //   const res =  axios.get(
  //     // `${process.env.REACT_APP_API_URL}/product-service/create/`,
  //     `${process.env.REACT_APP_API_URL}/api/service_image/${db_data?.id}/`,
  //   ).then((res)=>{
  //     if (res?.data && res.data.length > 0) {
  //       const data = res.data[0];

  //       if (data.image1) {
  //         setSelectedFile1(`${process.env.REACT_APP_API_URL}/${data.image1}`);
  //       }

  //       if (data.image2) {
  //         setSelectedFile2(`${process.env.REACT_APP_API_URL}/${data.image2}`);
  //       }

  //       if (data.image3) {
  //         setSelectedFile3(`${process.env.REACT_APP_API_URL}/${data.image3}`);
  //       }

  //       if (data.image4) {
  //         setSelectedFile4(`${process.env.REACT_APP_API_URL}/${data.image4}`);
  //       }
  //     }

  //  })
  // }
  //

  const handleImageClick = (ref) => {
    if (ref && ref.current) {
      ref.current.click();
    } else {
      console.error('File input ref is null');
    }
  };

  // const handleImageClick = (ref) => {
  //   console.log("image pciker ", ref)
  //   ref.current.click()
  // }
  const handleImageClick2 = (ref) => {
    console.log('image picker', ref);

    // Check if the device is a mobile device
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile) {
      // Mobile device - Ask the user for their choice
      if (
        window.confirm(
          "Do you want to take a new photo? Click 'OK' for camera, 'Cancel' for gallery."
        )
      ) {
        // User chooses to take a new photo
        ref.current.setAttribute('capture', 'environment');
      } else {
        // User chooses to pick an existing photo
        ref.current.removeAttribute('capture');
      }
    } else {
      // Non-mobile device - Standard file input behavior
      ref.current.removeAttribute('capture');
    }

    // Trigger the file input
    ref.current.click();
  };

  const handleFileSelect1 = (event) => {
    const file = event.target.files[0];
    setSelectedFile1(URL.createObjectURL(file));
    setDataPost((prevDataPost) => ({
      ...prevDataPost,
      add_speedometer: file,
    }));
  };
  // const handleFileSelect5 = (event) => {
  //   const file = event.target.files[0];
  //   setSelectedFile5(URL.createObjectURL(file));
  //   setDataPost((prevDataPost) => ({
  //     ...prevDataPost,
  //     add_photo: file,
  //   }));
  // };
  const handleFileSelect5 = async (event) => {
    const file = event.target.files[0];
    setSelectedFile5(URL.createObjectURL(file));

    // Create a FormData object to send the file
    const formData = new FormData();
    formData.append('Vincode', db_data?.vin_code); // Replace 'YourVincode' with the actual Vincode value
    formData.append('image', file);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/assembling-error-image/`,
        {
          method: 'POST',
          body: formData,
        }
      );

      if (response.ok) {
        const data = await response.json();
        toast.success('File uploaded successfully!');
      } else {
        toast.error('Failed to upload file');
      }
    } catch (error) {
      toast.error('Error uploading file');
    }
  };
  // const handleFileSelect5 = (event) => {
  //   setSelectedFile5(URL.createObjectURL(event.target.files[0]))

  //   setDataPost({
  //     ...dataPost,
  //     picture:event.target.files[0]
  //   })
  // }

  const handleFileSelect6 = (event) => {
    setSelectedFile6(URL.createObjectURL(event.target.files[0]));

    setDataPost({
      ...dataPost,
      video: event.target.files[0],
    });
  };

  const handleFileSelect2 = (event) => {
    const file = event.target.files[0];
    setSelectedFile2(URL.createObjectURL(file));
    setDataPost((prevDataPost) => ({
      ...prevDataPost,
      add_vincode: file,
    }));
  };
  const handleFileSelect3 = (event) => {
    const file = event.target.files[0];
    setSelectedFile3(URL.createObjectURL(file));
    setDataPost((prevDataPost) => ({
      ...prevDataPost,
      frontview: file,
    }));
  };

  const handleFileSelect4 = (event) => {
    const file = event.target.files[0];
    setSelectedFile4(URL.createObjectURL(file));
    setDataPost((prevDataPost) => ({
      ...prevDataPost,
      rareview: file,
    }));
  };

  const onChangeValue = (event, key) => {
    const value = event.target.value;
    setDataPost((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const [dataPost, setDataPost] = useState({
    product: db_data.id,
    user: profileId,
  });
  const saveAssembling = () => {
    // Checking if all required fields are filled
    // if (dataPost?.error_dettection === "") {
    //   alert("Please provide issue found");
    //   return;
    // }
    if (dataPost?.mechenical_coment === '') {
      toast.error('Please provide mechanical comment');
      // alert("Please provide mechanical comment");
      return;
    }

    // Checking if all pictures are selected
    if (!selectedFile1) {
      toast.error('Please select Add speedometer');
      // alert("Please select Add speedometer");
      return;
    }
    if (!selectedFile2) {
      toast.error('Please select add VinCode');
      // alert("Please select add VinCode");
      return;
    }
    if (!selectedFile3) {
      toast.error('Please select add front 45 file');
      // alert("Please select add front 45 file");
      return;
    }
    if (!selectedFile4) {
      toast.error('Please select add rare 45 file');
      // alert("Please select add rare 45 file");
      return;
    }

    // Creating a FormData object and appending data
    const formData = new FormData();

    formData.append('vin_picture', dataPost?.add_vincode);
    formData.append('errors', dataPost?.error_dettection);
    // formData.append("errors_picture", dataPost?.add_photo);
    formData.append('speedometer_picture', dataPost?.add_speedometer);
    formData.append('front_45_view_picture', dataPost?.frontview);
    formData.append('rear_45_view_picture', dataPost?.rareview);
    formData.append('mechanic_comment', dataPost?.mechenical_coment);
    formData.append('product', db_data?.id);
    formData.append('user', profileId);

    // Sending FormData via fetch
    fetch(`${process.env.REACT_APP_API_URL}/api/create/assembling/`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);
        toast.success('Assembling created successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        // Navigate or perform other actions upon successful submission
        navigate('/vehicleData');

        // Additional fetch for historical note creation
        const formData2 = new FormData();
        formData2.append(
          'description',
          `Assembling saved against SKU ${db_data?.sku}`
        );
        formData2.append(
          'estonian_description',
          `Sõiduki komplekteerimine tehtud ${profile} poolt ettevõttest ${company_name}`
        );
        formData2.append(
          'latvian_description',
          `Montāža saglabāta pret SKU ${db_data?.sku}`
        );
        formData2.append(
          'finnish_description',
          `Koonti tallennettu SKU vastaan ${db_data?.sku}`
        );
        formData2.append('historical_note', ``);
        formData2.append('vehicle', db_data?.id);
        formData2.append('owner_email', db_data?.owner_email);
        formData2.append('plate_number', db_data?.plate_number);
        formData2.append('vin_code', db_data?.vin_code);

        fetch(`${process.env.REACT_APP_API_URL}/history/create/`, {
          method: 'POST',
          body: formData2,
          redirect: 'follow',
        })
          .then((response) => response.text())
          .then((result) => {
            console.log('Expected result:', result);
            axios
              .post(
                `${process.env.REACT_APP_API_URL}/api/delete_temporary_warranty/${responseData.id}/`
              )
              .then((tempRes) => {
                console.log('Temporary warranty Response:', tempRes);
              })
              .catch((tempError) => {
                console.error('Error in Temporary warranty POST:', tempError);
              });
          });
      })
      .catch((error) => {
        console.error('Error:', error);
        toast.error('Error creating work', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      });
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  // const [showVinModal, setShowVinModal] = useState(false);
  const handlevinImageClick = () => {
    setShowVinModal(true);
  };

  const handlevinCloseModaly = () => {
    setShowVinModal(false);
  };
  const handlespeedoImageClick = () => {
    setShowspeedoModal(true);
  };

  const handlespeedoCloseModaly = () => {
    setShowspeedoModal(false);
  };

  const handlefront_45ImageClick = () => {
    setShowfront_45Modal(true);
  };

  const handlefront_45CloseModaly = () => {
    setShowfront_45Modal(false);
  };

  const handlerear_45ImageClick = () => {
    setShowrear_45Modal(true);
  };

  const handlerear_45CloseModaly = () => {
    setShowrear_45Modal(false);
  };
  const handleleftViewCloseModaly = () => {
    setShowLeftViewModal(false);
  };
  const handleRightViewCloseModaly = () => {
    setShowRightViewModal(false);
  };

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous route
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, '0'); // Ensure day is 2 digits (e.g., '03' instead of '3')
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
    const year = date.getFullYear();
    const hour = String(date.getHours()).padStart(2, '0'); // Ensure hour is 2 digits
    const minute = String(date.getMinutes()).padStart(2, '0'); // Ensure minute is 2 digits

    return `${day}/${month}/${year} at ${hour}:${minute}`;
  };

  return (
    <div className='flex'>
      <Sidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />

      <Header setOpenSidebar={setOpenSidebar} />

      <main
        className='md:ml-[16.25rem] xl:ml-[21.125rem] pt-[62px] md:pt-[6.5625rem] w-full'
        ref={componentRef}
        id='displayBlackInPrintMode'
      >
        {/* ADD NEW SERVICE */}
        <div className='px-[26px] md:pl-9 xl:pl-[3.25rem] md:pr-10 pt-[22px] md:pt-[42px] xl:pt-14 pb-11 xl:pb-16'>
          <div className='flex flex-col md:flex-row mb-[22px] md:mb-[30px] xl:mb-14'>
            {/* LEFT */}
            <div className='w-full md:w-1/2 flex flex-col self-center'>
              {/* IMAGE */}
              <div className=' '>
                <h3 className='text-blue text-f_16_l_20 md:text-f_22_l_28 xl:text-f_35_l_44 font-supremeBold'>
                  {t('assembling')}
                </h3>
              </div>
            </div>

            {/* RIGHT */}
            <div className='w-full md:w-1/2 flex flex-col self-center'>
              <div className='md:pl-5 xl:pl-[30px]'>
                {/* BIKE NAME */}
                <div className=''>
                  <h3 className='bg-gradient-text text-f_19_l_24 md:text-f_22_l_28 xl:text-f_27_l_34 text-left font-supremeBold'>
                    {db_data?.model_name}, {db_data?.year}, {db_data?.color}
                  </h3>
                </div>
              </div>
            </div>
          </div>

          {/* VEHICLE PHOTOS */}
          <div className='mb-[67px] md:mb-[41px] xl:mb-[90px]'>
            {/* TITLE */}
            <div className='mb-6 md:mb-14 xl:mb-[43px]'>
              <h1
                className='text-f_20_l_25 md:text-f_22_l_28 text-white-100 mb-3 md:mb-2.5'
                id='displayBlackInPrintMode'
              >
                {/* {t("Vehicle_photos_desc") } */}
                {t('check_the_vin_code')}{' '}
                <span className='text-blue text-f_16_l_20 md:text-f_22_l_28 xl:text-f_35_l_44'>
                  {db_data?.vin_code}
                </span>{' '}
                {t('and_make_photo')}
              </h1>
            </div>

            <div className='grid grid-cols-2 xl:grid-cols-4 gap-5 md:gap-y-[27px] md:gap-x-[30px]'>
              {/* IMAGE 2 */}
              <div>
                {/* IMAGE AREA */}
                <div
                  className='w-full h-[156px] md:h-[196px] xl:h-[168px] bg-contain bg-no-repeat bg-center flex justify-center items-center bg-black-500 rounded-[10px]'
                  style={{
                    backgroundImage: hasAssembling?.vin_picture
                      ? `url(${hasAssembling?.vin_picture})`
                      : 'none',
                    backgroundSize: 'cover', // Stretch the background image to cover the container
                    backgroundPosition: 'center',
                  }}
                  onClick={handlevinImageClick}
                >
                  {!hasAssembling?.vin_picture && (
                    <div className='flex flex-col justify-center items-center cursor-pointer text-center'>
                      <img src={placeholder_img} alt='add-img' />
                      <div
                        className='text-f_18_l_28 mt-3.5 break-all hyphens-auto'
                        style={{ wordBreak: 'break-word' }}
                      >
                        {t('addvincode')}
                      </div>
                    </div>
                  )}
                </div>
                {/* {showVinModal && (
                  <ImageModal
                    imageUrl={hasAssembling?.vin_picture}
                    onClose={handlevinCloseModaly}
                  />
                )} */}
                <Lightbox
                  open={showVinModal}
                  close={handlevinCloseModaly}
                  slides={slidesFormatter([hasAssembling?.vin_picture])}
                  plugins={[
                    Captions,
                    Fullscreen,
                    Slideshow,
                    Thumbnails,
                    Video,
                    Zoom,
                  ]}
                />

                {/* INPUT */}
                {/* <input
                    type="file"
                    ref={fileInputRefaddvincode}
                    style={{ visibility: false, display: "none" }}
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={handleFileSelect2}
                  /> */}
              </div>
            </div>
          </div>

          {/*  Failure description */}

          <div className='mb-[67px] md:mb-[41px] xl:mb-[90px]'>
            {/* TITLE */}
            <div className='mb-6 md:mb-14 xl:mb-[43px]'>
              <h3 className='text-f_22_l_28 md:text-f_27_l_34 font-supremeBold mb-[25px] md:mb-5'>
                {/* {t("Failure_description") } */}
                {t('assembly_confirmation')}
              </h3>
            </div>

            <div className='grid grid-cols-2 xl:grid-cols-4 gap-5 md:gap-y-[27px] md:gap-x-[30px]'>
              {[
                {
                  file: hasAssembling?.speedometer_picture,
                  placeholder: 'Addspeedometer',
                  onClick: handlespeedoImageClick,
                  showModal: showspeedoModal,
                  modalClose: handlespeedoCloseModaly,
                },
                {
                  file: hasAssembling?.front_45_view_picture,
                  placeholder: 'add45front',
                  onClick: handlefront_45ImageClick,
                  showModal: showfront_45Modal,
                  modalClose: handlefront_45CloseModaly,
                },
                {
                  file: hasAssembling?.rear_45_view_picture,
                  placeholder: 'add45back',
                  onClick: handlerear_45ImageClick,
                  showModal: showrear_45Modal,
                  modalClose: handlerear_45CloseModaly,
                },
                {
                  file: hasAssembling?.left_view_picture,
                  placeholder: 'addleft',
                  onClick: handleleftViewCloseModaly,
                  showModal: showLeftViewModal,
                  modalClose: handleleftViewCloseModaly,
                },
                {
                  file: hasAssembling?.right_view_picture,
                  placeholder: 'addright',
                  onClick: handleRightViewCloseModaly,
                  showModal: showRightViewModal,
                  modalClose: handleRightViewCloseModaly,
                },
              ].map((image, index) => (
                <div key={index}>
                  <div
                    className='w-full h-[156px] md:h-[196px] xl:h-[168px] bg-contain bg-no-repeat bg-center flex justify-center items-center bg-black-500 rounded-[10px]'
                    style={{
                      backgroundImage: image.file
                        ? `url(${image.file})`
                        : 'none',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                    onClick={image.onClick}
                  >
                    {!image.file && (
                      <div className='flex flex-col justify-center items-center cursor-pointer text-center'>
                        <img src={placeholder_img} alt='add-img' />
                        <div
                          className='text-f_18_l_28 mt-3.5 break-all hyphens-auto'
                          style={{ wordBreak: 'break-word' }}
                        >
                          {t(image.placeholder)}
                        </div>
                      </div>
                    )}
                  </div>
                  {/* {image.showModal && (
                    <ImageModal
                      imageUrl={image.file}
                      onClose={image.modalClose}
                    />
                  )} */}
                  <Lightbox
                    open={image.showModal}
                    close={image.modalClose}
                    slides={slidesFormatter([
                      hasAssembling?.speedometer_picture,
                      hasAssembling?.front_45_view_picture,
                      hasAssembling?.rear_45_view_picture,
                      hasAssembling?.left_view_picture,
                      hasAssembling?.right_view_picture,
                    ])}
                    plugins={[
                      Captions,
                      Fullscreen,
                      Slideshow,
                      Thumbnails,
                      Video,
                      Zoom,
                    ]}
                  />
                </div>
              ))}
            </div>
          </div>

          <div id='pageBreakInPrintMode'>
            <div className='mb-7'>
              <h3 className='text-f_22_l_28 md:text-f_27_l_34 font-supremeBold mb-[25px] md:mb-5'>
                {t('mechanic_comment')}:
              </h3>
              <textarea
                value={hasAssembling?.mechanic_comment}
                readOnly
                onChange={(event) => {
                  onChangeValue(event, 'mechenical_coment');
                }}
                className='h-[169px] border border-white-100 rounded-[10px] bg-transparent w-full'
              />
            </div>
          </div>
          <div className='mb-7'>
            <button
              className='w-48 h-12 rounded-[10px] border border-white text-f_14_l_18 font-supremeMedium uppercase mb-[20px]' // Increased width
              onClick={toggleVisibility}
            >
              {t('issues_found')}
            </button>
            {isVisible && (
              <div
                style={{
                  border: '2px solid red',
                  borderRadius: '10px',
                  padding: '10px',
                }}
              >
                <p className='text-f_22_l_28 md:text-f_27_l_34 font-supremeBold mb-[25px] md:mb-5'>
                  {/* {t("Failure_description") } */}
                  {t('issues_found_during_the_assembly_process')}
                </p>

                <textarea
                  // value={dataPost?.failure_description}
                  value={hasAssembling?.errors}
                  readOnly
                  className='h-[169px] border border-white-100 rounded-[10px] bg-transparent w-full mb-[40px]' // Added margin-bottom here
                />
                <button
                  className='mt-5 w-48 h-12 rounded-[10px] border border-white text-f_14_l_18 font-supremeMedium uppercase mb-[20px]' // Increased width
                  onClick={() => navigate('/service/Waranty')}
                >
                  {t('open_warranty')}
                </button>
              </div>
            )}
          </div>

          <div className='flex items-center justify-center w-full mb-10'>
            <div className='bg-gradient-text font-supremeBold text-2xl w-auto px-4 py-2 text-center'>
              submitted by {hasAssembling?.user.name} at{' '}
              {hasAssembling?.created_at
                ? formatDate(hasAssembling.created_at)
                : 'N/A'}{' '}
              from company {hasAssembling?.user.company.company_name}
            </div>
          </div>
          {/* SAVE SERVICE DATA BUTTON */}
          <div className='flex justify-center items-center'>
            {' '}
            {/* Add items-center if vertical centering is also needed */}
            <button
              className='w-[240px] h-[52px] md:h-[62px] bg-gradient rounded-[10px] text-f_16_l_20 md:text-f_22_l_28 font-supremeMedium'
              onClick={handleBackClick}
            >
              {/* {t("Save_warranty_data") } */}
              Back
            </button>
            <button
              className='w-[240px] h-[52px] md:h-[62px] bg-gradient rounded-[10px] text-f_16_l_20 md:text-f_22_l_28 font-supremeMedium ml-4'
              onClick={handlePrint}
            >
              Print
            </button>
          </div>

          <br />
        </div>
      </main>
    </div>
  );
}

export default DisplayAssembling;
