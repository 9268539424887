// import logo from './logo.svg';
// import './App.css';
import { Route, Routes, BrowserRouter, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import VehicleData from './Pages/VehicleData';
import Displaywarrantyregister from './Pages/displaywarrantyregistration';
import Login from './Pages/Login';
import Dashboard from './Pages/Dashboard';
import Changepassword from './Pages/changePassword';
import VINcode from './Pages/Vincode';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Protected from './protected';
import LoginProtected from './loginProtect';
import AddNewService from './Pages/AddNewService';
import maintainence from './Pages/maintainence';
import Waranty from './Pages/waranty';
import Settings from './Pages/Settings';
import Repairing from './Pages/repairing';
import Warrantyregister from './Pages/WarrantyRegistration';
import Bulletin from './Pages/bulletin';
import Assembling from './Pages/Assembling';
import { useTranslation } from 'react-i18next';
import DisplayAssembling from './Pages/displayassembling';
import Addwork from './Pages/Addwork';
import AsembelingAccesories from './Pages/AsembelingAccesories';
import History_button from './Pages/history_button';
import ForgotPasswordWithReCaptcha from './Pages/forgotPassword';
import ResetPassword from './Pages/resetPassword';
// import Assembling from "./Pages/Assembling";
import Progress from './Pages/progress';
function App() {
  const { t } = useTranslation();
  // const [navigate,setNavigate] = useState("a")
  // const location=useLocation()

  // useEffect(()=>{
  //   setNavigate(location.pathname)
  //   console.log(navigate)
  // // },[location])
  // const [openSidebar, setOpenSidebar] = useState(false);
  return (
    <>
      <div className='App' style={{ backgroundColor: '#0a0a0a' }}>
        <ToastContainer />

        {/* {navigate==="a" &&<Sidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} activeMe="dashboard"/>}
    <div>
    <Header setOpenSidebar={setOpenSidebar} />
    </div> */}
        {/* <BrowserRouter> */}
        <Routes>
          <Route
            path='/'
            exact
            element={
              <LoginProtected
                Children={Login}
                isLoggedIn={localStorage.getItem('token') == null}
              />
            }
          />
          <Route
            path='/forgot-password'
            exact
            element={
              <LoginProtected
                Children={ForgotPasswordWithReCaptcha}
                isLoggedIn={localStorage.getItem('token') == null}
              />
            }
          />
          <Route
            path='/reset-password'
            exact
            element={
              <LoginProtected
                Children={ResetPassword}
                isLoggedIn={localStorage.getItem('token') == null}
              />
            }
          />
          <Route
            path='/dashboard'
            exact
            element={
              <Protected
                Children={Dashboard}
                isLoggedIn={localStorage.getItem('token') != null}
              />
            }
          />
          <Route
            path='/progress'
            exact
            element={
              <Protected
                Children={Progress}
                isLoggedIn={localStorage.getItem('token') != null}
              />
            }
          />
          <Route
            path='/vin'
            exact
            element={
              <Protected
                Children={VINcode}
                isLoggedIn={localStorage.getItem('token') != null}
              />
            }
          />
          <Route
            path='/vehicleData'
            exact
            element={
              <Protected
                Children={VehicleData}
                isLoggedIn={localStorage.getItem('token') != null}
              />
            }
          />
          <Route
            path='/settings'
            exact
            element={
              <Protected
                Children={Settings}
                isLoggedIn={localStorage.getItem('token') != null}
              />
            }
          />
          <Route
            path='/service/addNewService'
            exact
            element={
              <Protected
                Children={AddNewService}
                isLoggedIn={localStorage.getItem('token') != null}
              />
            }
          />
          <Route
            path='/service/maintainence'
            exact
            element={
              <Protected
                Children={maintainence}
                isLoggedIn={localStorage.getItem('token') != null}
              />
            }
          />
          <Route
            path='/service/Waranty'
            exact
            element={
              <Protected
                Children={Waranty}
                isLoggedIn={localStorage.getItem('token') != null}
              />
            }
          />
          <Route
            path='/service/repairings'
            exact
            element={
              <Protected
                Children={Repairing}
                isLoggedIn={localStorage.getItem('token') != null}
              />
            }
          />
          <Route
            path='/service/bulletin'
            exact
            element={
              <Protected
                Children={Bulletin}
                isLoggedIn={localStorage.getItem('token') != null}
              />
            }
          />
          <Route
            path='/vehicleData/warrantyregisterartion'
            exact
            element={
              <Protected
                Children={Warrantyregister}
                isLoggedIn={localStorage.getItem('token') != null}
              />
            }
          />
          <Route
            path='/service/Assembling'
            exact
            element={
              <Protected
                Children={Assembling}
                isLoggedIn={localStorage.getItem('token') != null}
              />
            }
          />
          {/* <Route path="/service/addNewService" element={<AddNewService/>} /> */}
          <Route
            path='/service/Addwork'
            exact
            element={
              <Protected
                Children={Addwork}
                isLoggedIn={localStorage.getItem('token') != null}
              />
            }
          />
          <Route
            path='/service/Settings'
            exact
            element={
              <Protected
                Children={Settings}
                isLoggedIn={localStorage.getItem('token') != null}
              />
            }
          />
          <Route
            path='/service/AsembelingAccesories'
            exact
            element={
              <Protected
                Children={AsembelingAccesories}
                isLoggedIn={localStorage.getItem('token') != null}
              />
            }
          />
          <Route
            path='/service/history'
            exact
            element={
              <Protected
                Children={History_button}
                isLoggedIn={localStorage.getItem('token') != null}
              />
            }
          />
          <Route
            path='/vehicleData/displaywarrantyregister'
            exact
            element={
              <Protected
                Children={Displaywarrantyregister}
                isLoggedIn={localStorage.getItem('token') != null}
              />
            }
          />
          <Route
            path='/vehicleData/displayassembling'
            exact
            element={
              <Protected
                Children={DisplayAssembling}
                isLoggedIn={localStorage.getItem('token') != null}
              />
            }
          />
          <Route
            path='/changepassword'
            exact
            element={
              <Protected
                Children={Changepassword}
                isLoggedIn={localStorage.getItem('token') != null}
              />
            }
          />
        </Routes>
        {/* </BrowserRouter> */}
      </div>
    </>
  );
}

export default App;
