import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../components/Common/Header';
import Show from '../components/Common/show';
import Sidebar from '../components/Common/Sidebar';
import { loaderActions } from '../state/actions';

function VehicleData() {
  const storedItem = localStorage.getItem('selectedLanguage');
  // const showaccessories=localStorage.getItem("show_accessories");
  const permis = localStorage.getItem('show_accessories');
  const location = useLocation();
  // console.log("--efdgffgfdgdffhfhvehiclefgf",permis)

  const [warrantyModal, setWarrantyModal] = useState(false);
  const handleClick6 = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/warranty-registration/${db_data?.id}/`
      );
      const data = await response.json();
      if (data.length > 0) {
        // Navigate to a different route if response length is greater than 0
        toast.error(
          'Warranty Registration is already submitted against this product',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          }
        );
      } else {
        // Show modal if response length is 0
        navigate('/vehicleData/warrantyregisterartion');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle error here
    }
  };

  // Parsing the retrieved item as a JSON object
  const parsedItem = JSON.parse(storedItem);

  // Accessing the 'label' property of the parsed object
  const label = parsedItem.label;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [buttonStyle, setButtonStyle] = useState({});
  const [companyData, setCompanyData] = useState(null);
  const dispatch = useDispatch();

  React.useEffect(() => {
    // console.log("data=--_>> ",localStorage.getItem("vinnData"))
    if (!localStorage.getItem('vinnData')) {
      navigate('/vinn');
    } else {
      getApiData();
    }
  }, []);

  const data = localStorage.getItem('vinnData');
  const parsed_data = JSON.parse(data);
  const [showModal, setShowModal] = useState(false);

  function downloadPdfFile(pdfUrl) {
    // Create an anchor element
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.target = '_blank';
    link.download = 'your_file_name.pdf'; // Set the desired file name here

    // Trigger a click event on the anchor element
    link.click();
  }
  const fetchDataByLanguage = (language) => {
    setShowModal2(false);

    // Construct the API URL using the selected language and db_data
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/filter_service_manual/${language}/${db_data?.Factory_name}/`;

    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log('Fetched data:', data);
        if (data.service_manual_pdf) {
          // Check if data is not null and user_manual_pdf exists
          // Download the PDF file
          downloadPdfFile(`${data.service_manual_pdf}`);
        } else {
          toast.error('No Service manual found', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        alert('Error fetching data. Please try again later.');
      });
  };
  const fetchDataByLanguageusermanual = (language) => {
    setshowModalusermanual(false);

    // Construct the API URL using the selected language and db_data
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/filter_user_manual/${language}/${db_data?.Factory_name}/`;

    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log('Fetched data:', data);
        if (data.user_manual_pdf) {
          // Check if data is not null and user_manual_pdf exists
          // Download the PDF file
          downloadPdfFile(`${data.user_manual_pdf}`);
        } else {
          toast.error('No user manual found', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        alert('Error fetching data. Please try again later.');
      });
  };
  // const fetchDataByLanguage = (language) => {
  //   setShowModal2(false)

  //   // Construct the API URL using the selected language and db_data
  //   const apiUrl = `${process.env.REACT_APP_API_URL}/api/filter_service_manual/${language}/${db_data?.id}/`;
  //   // setShowModal(false);
  //   // Make the API call using the apiUrl
  //   fetch(apiUrl)
  //     .then((response) => {
  //       if (!response.ok) {
  //         // Handle the case where the API request fails
  //         throw new Error('Network response was not ok');
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       if (data?.Service_manual_pdf) {
  //         // Handle the API response data here
  //         console.log(data);

  //         downloadPdfFile(`${process.env.REACT_APP_API_URL}/${data?.Service_manual_pdf}`)
  //       } else {
  //         // If data is empty, show an alert
  //         toast.error('No Service manual found', {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "colored",
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data:', error);
  //       // Handle any other errors that occur during the request
  //       alert('Error fetching data. Please try again later.');
  //     });
  // };

  function formatTimestamp(timestamp) {
    const dateObj = new Date(timestamp);

    const dateStr = dateObj.toLocaleDateString('en-US');
    const parts = dateStr.split('/');
    const formattedDate = `${parts[1]}/${parts[0]}/${parts[2]}`;
    const timeStr = dateObj.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    });

    return `${formattedDate} ${timeStr}`;
  }

  const handlemainButtonClick = () => {
    if (
      db_data?.warranty_end_date !== undefined &&
      db_data?.warranty_end_date !== null
    ) {
      navigate('/service/addNewService');
      return;
    }
    if (!hasAssembling) {
      toast.error('Please fill assembling.');
    } else if (hasAssembling && !hasWarrantyRegistration) {
      toast.error('Fill the vehicle registration first.');
    } else if (hasAssembling && hasWarrantyRegistration) {
      navigate('/service/addNewService');
    }
  };
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
  };
  const [hasWarrantyRegistration, setHasWarrantyRegistration] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false); // Define the isLoaded state
  const db_data = parsed_data?.data;
  const [db_data_history, setDb_Data] = useState(null);
  const dateStr = db_data?.date_of_manufacture;
  const dateObj = new Date(dateStr);
  const year = dateObj.getFullYear();
  const getApiData = async () => {
    console.log('db_data+++++>> ', db_data);
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/products/${db_data?.sku}/history/`
      // `${process.env.REACT_APP_API_URL}/products/15/history/`
    );
    console.log('Response=====> ', res?.data);
    if (res?.data?.length) {
      // const reversedData = res?.data;
      const reversedData = [...res.data].reverse();
      setDb_Data(reversedData);
    }
  };
  React.useEffect(() => {
    getApiData();
  }, []);
  //show history
  const [showHistory, setShowHistory] = useState(false);
  const [workStatusData, setWorkStatusData] = useState(null);

  const toggleHistory = () => {
    setShowHistory(!showHistory);
  };

  const historyRef = React.useRef(null);
  const [hasAssembling, setHasAssembling] = useState(false);
  // React.useEffect(() => {
  //   const fetchAssemblingAndWarrantyData = async () => {
  //     console.log("Fetching assembling and warranty data...");
  //     try {
  //       // Fetch assembling data
  //       const assemblingResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/assembling/product/${db_data?.id}/`);
  //       const assemblingData = await assemblingResponse.json();
  //       console.log("Assembling Data:", assemblingData);
  //       const hasAssemblingData = assemblingData.length > 0;
  //       setHasAssembling(hasAssemblingData);

  //       if (hasAssemblingData) {
  //         // Fetch warranty registration data if assembling data exists
  //         const warrantyResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/warranty-registration/${db_data?.id}/`);
  //         const warrantyData = await warrantyResponse.json();
  //         console.log("Warranty Data:", warrantyData);
  //         setHasWarrantyRegistration(warrantyData.length > 0);
  //       }

  //       setIsLoaded(true); // Set loading state to true when data fetching is complete
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //       // Handle error here
  //     }
  //   };

  //   if (db_data?.id) {
  //     fetchAssemblingAndWarrantyData();
  //   }
  // }, [db_data?.id]);
  React.useEffect(() => {
    const fetchAssemblingAndWarrantyData = async () => {
      console.log('Fetching assembling and warranty data...');
      try {
        // Fetch company data
        const companyResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/vehicles/get_company/${db_data?.id}/`
        );
        const companyData = await companyResponse.json();
        console.log('Company Data:', companyData?.company?.company_name);
        setCompanyData(companyData);

        // Fetch assembling data
        const assemblingResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/api/assembling/product/${db_data?.id}/`
        );
        const assemblingData = await assemblingResponse.json();
        console.log('Assembling Data:', assemblingData);
        const hasAssemblingData = assemblingData.length > 0;
        setHasAssembling(hasAssemblingData);

        if (hasAssemblingData) {
          // Fetch warranty registration data if assembling data exists
          const warrantyResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/api/warranty-registration/${db_data?.id}/`
          );
          const warrantyData = await warrantyResponse.json();
          console.log('Warranty Data:', warrantyData);
          setHasWarrantyRegistration(warrantyData.length > 0);
        }

        setIsLoaded(true); // Set loading state to true when data fetching is complete
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error here
      }
    };

    if (db_data?.id) {
      fetchAssemblingAndWarrantyData();
    }
  }, [db_data?.id]);
  React.useEffect(() => {
    if (showHistory) {
      // Scroll to the history section when it's shown
      historyRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [showHistory]);

  // HISTORY LIST DATA
  // const items = [
  //   {
  //     length: 1,
  //     timestamp: "2022-01-05 16:00",
  //     description: "A vehicle ordered from the factory",
  //   },
  //   {
  //     length: 2,
  //     timestamp: "2022-03-02 8:30",
  //     description:
  //       "Vehicle registered in the system and sent out from the factory",
  //   },
  //   {
  //     length: 3,
  //     timestamp: "2022-04-11 11:18",
  //     description: "Transport from China - to Estonia",
  //   },
  //   {
  //     length: 4,
  //     timestamp: "2022-04-15 13:45",
  //     description: "Arrived in Motohobi warehouse",
  //   },
  //   {
  //     length: 5,
  //     timestamp: "2022-04-15 16:45",
  //     description: "Motorcycle assembly Ants Käbi, Motohobi",
  //   },
  //   {
  //     length: 6,
  //     timestamp: "2022-04-17 17:00",
  //     description:
  //       "Sales Contract, Motorcycle Awarded to the User Matti Kasela",
  //   },
  //   {
  //     length: 7,
  //     timestamp: "2022-08-20 12:15",
  //     description:
  //       "1000km maintenance reservation, spare parts ordered. Maintenance time 2022-08-27",
  //   },
  // ];

  const [showModal2, setShowModal2] = useState(false);
  const [showModalusermanual, setshowModalusermanual] = useState(false);
  const handleDownload = () => {
    const fileId = '1mLuFXX6s-_brFA8tCZCCovhO0-vfEKxD'; // Replace with your Google Drive file ID
    const url = `https://drive.google.com/file/d/16ShLrViH0V2ggHjso_ym_yWY6lJWTssu/view`;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'file.pdf'); // Set the desired file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleButtonClick = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/bulletins/${db_data.vin_code}/`
      );

      if (response.data.length > 0 /* Check if data is as expected */) {
        // Store data in local storage
        localStorage.setItem('bulletinData', JSON.stringify(response.data));
        console.log(response.data);
        // Change button color if data is present
        setButtonStyle({ border: '1px solid #009bb4', color: 'red' });

        // Navigate to '/service/maintenance'
        navigate('/service/bulletin');
      } else {
        // Show alert if no data
        toast.error('No data found', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      alert('Error fetching data');
    }
  };

  const handleDownloadone = () => {
    const fileId = '1AZSDXHKkJWcB4W34CIUI5gUne_gfQ1Fu'; // Replace with your Google Drive file ID
    const url = `https://drive.google.com/file/d/1nLsQHMeheszp8UTU2I35BFT9vGhnOWC_/view`;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'my-custom-name.pdf'); // Set your desired file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  // // Reverse the order of the items array
  // items.reverse();
  const image_url = `${db_data?.image}`;

  useEffect(() => {
    dispatch(loaderActions.showLoader());
    const timer = setTimeout(() => {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/addwork/add-work/check-status/${db_data?.vin_code}/`
        )
        .then((response) => {
          setWorkStatusData(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        })
        .finally(() => {
          dispatch(loaderActions.hideLoader());
        });
    }, 3000);

    // Cleanup function to clear the timer if the component unmounts before the timeout is reached
    return () => clearTimeout(timer);
  }, [location, db_data?.vin_code]);

  return (
    <>
      <div className='flex'>
        <Sidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />

        <Header setOpenSidebar={setOpenSidebar} />

        <main className='md:ml-[16.25rem] xl:ml-[21.125rem] pt-[62px] md:pt-[6.5625rem] w-full'>
          {/* VEHICLE DATA */}
          <div className='px-[26px] md:pl-9 xl:pl-[3.25rem] md:pr-10 pt-[22px] md:pt-[42px] xl:pt-14 pb-11 xl:pb-16'>
            <div className='flex flex-col md:flex-row'>
              {/* LEFT */}
              <div className='w-full md:w-1/2 md:pt-3 xl:pt-12'>
                {/* IMAGE */}
                <div className='mb-10 md:mb-20'>
                  <img src={image_url} alt='bike-img' />
                </div>

                {/* BUTTONS DESKTOP */}
                <div className='hidden md:block'>
                  <div className='flex flex-col gap-3.5 xl:gap-5 items-center'>
                    <div className='w-full grid grid-cols-2 gap-3.5 xl:gap-5'>
                      {/* MAINTENANCE */}
                      <button
                        className={`w-full h-12 xl:h-[73px] rounded-[10px] border border-white text-f_14_l_18 xl:text-f_22_l_28 font-supremeMedium uppercase disabled:text-gray-500 disabled:cursor-not-allowed ${
                          workStatusData?.is_maintenance
                            ? 'text-[#FFD009] border-[#FFD009]'
                            : ''
                        }`}
                        onClick={() => handlemainButtonClick()}
                        disabled={!workStatusData?.is_maintenance}
                      >
                        {t('Maintenance')}
                      </button>

                      {/* REPAIRING */}
                      <button
                        className={`w-full h-12 xl:h-[73px] rounded-[10px] border border-white text-f_14_l_18 xl:text-f_22_l_28 font-supremeMedium uppercase disabled:text-gray-500 disabled:cursor-not-allowed ${
                          workStatusData?.is_repair
                            ? 'text-[#FFD009] border-[#FFD009]'
                            : ''
                        }`}
                        onClick={() => navigate('/service/maintainence')}
                        disabled={!workStatusData?.is_repair}
                      >
                        {t('Repairing')}
                      </button>

                      {/* OEM PARTS */}
                      <button
                        className='w-full h-12 xl:h-[73px] rounded-[10px] border border-white text-f_14_l_18 xl:text-f_22_l_28 font-supremeMedium uppercase'
                        onClick={() =>
                          window.open(
                            `https://cfmoto.motohobi.ee/product/${db_data?.year}-${db_data?.series}-${db_data?.Factory_name}`,
                            '_blank'
                          )
                        }
                      >
                        {t('Parts')}
                      </button>

                      {localStorage.getItem('show_accessories') === 'true' && (
                        <button
                          className='w-full h-12 xl:h-[73px] rounded-[10px] border border-white text-f_14_l_18 xl:text-f_22_l_28 font-supremeMedium uppercase'
                          onClick={() =>
                            window.open(
                              `https://cfmoto.motohobi.ee/product/${db_data?.year}-accessory-${db_data?.Factory_name}`,
                              '_blank'
                            )
                          }
                        >
                          {t('Accessories')}
                        </button>
                      )}

                      {/* SERVICE MANUAL */}
                      <button
                        className='w-full h-12 xl:h-[73px] rounded-[10px] border border-white text-f_14_l_18 xl:text-f_22_l_28 font-supremeMedium uppercase'
                        onClick={() => {
                          fetchDataByLanguage('EN');
                          // setShowModal2(true)
                        }}
                      >
                        {t('Service_manual')}
                      </button>
                      <Modal
                        isOpen={showModal2}
                        onRequestClose={() => setShowModal2(false)}
                        className='fixed  inset-0 flex items-center justify-center z-50'
                        overlayClassName='fixed inset-0 bg-black bg-opacity-50'
                      >
                        <div className='bg-main rounded-lg p-6 w-96'>
                          <h1 className='text-xl font-bold mb-4'>
                            Select Language For Service Manual
                          </h1>
                          <div className='mb-6 grid grid-cols-1 md:grid-cols-2 gap-4'>
                            <button
                              className='selectable-button border border-gray-300 rounded-lg p-2'
                              onClick={() => fetchDataByLanguage('EN')}
                              data-language='english'
                            >
                              English
                            </button>
                            <button
                              className='selectable-button border border-gray-300 rounded-lg p-2'
                              onClick={() => fetchDataByLanguage('ET')}
                              data-language='estonian'
                            >
                              Estonian
                            </button>
                            <button
                              className='selectable-button border border-gray-300 rounded-lg p-2'
                              onClick={() => fetchDataByLanguage('LV')}
                              data-language='Latvian'
                            >
                              Latvian
                            </button>
                            <button
                              className='selectable-button border border-gray-300 rounded-lg p-2'
                              onClick={() => fetchDataByLanguage('FI')}
                              data-language='Finnish'
                            >
                              Finnish
                            </button>
                          </div>

                          <div className='flex justify-end mt-6'>
                            {/* <button
                    className="px-4 py-2  text-white rounded hover:bg-blue-700 mr-2"
                    tyle={{ backgroundColor: "#009bb4" }}
                    onClick={()=>{
                      
                    
                      setShowModal(false);
                      
                    }}
                  > */}
                            {/* Confirm
                  </button> */}
                            <button
                              className='px-4 py-2 bg-white text-white rounded hover:bg-gray-500'
                              s
                              onClick={() => {
                                setShowModal2(false);
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </Modal>

                      {/* USER MANUAL */}
                      <button
                        className='w-full h-12 xl:h-[73px] rounded-[10px] border border-white text-f_14_l_18 xl:text-f_22_l_28 font-supremeMedium uppercase'
                        onClick={() => {
                          setshowModalusermanual(true);
                        }}
                      >
                        {t('User_manual')}
                      </button>
                      <Modal
                        isOpen={showModalusermanual}
                        onRequestClose={() => setshowModalusermanual(false)}
                        className='fixed  inset-0 flex items-center justify-center z-50'
                        overlayClassName='fixed inset-0 bg-black bg-opacity-50'
                      >
                        <div className='bg-main rounded-lg p-6 w-96'>
                          <h1 className='text-xl font-bold mb-4'>
                            Select language for User Manual
                          </h1>
                          <div className='mb-6 grid grid-cols-1 md:grid-cols-2 gap-4'>
                            <button
                              className='selectable-button border border-gray-300 rounded-lg p-2'
                              onClick={() =>
                                fetchDataByLanguageusermanual('EN')
                              }
                              data-language='english'
                            >
                              English
                            </button>
                            <button
                              className='selectable-button border border-gray-300 rounded-lg p-2'
                              onClick={() =>
                                fetchDataByLanguageusermanual('ET')
                              }
                              data-language='estonian'
                            >
                              Estonian
                            </button>
                            <button
                              className='selectable-button border border-gray-300 rounded-lg p-2'
                              onClick={() =>
                                fetchDataByLanguageusermanual('LV')
                              }
                              data-language='Latvian'
                            >
                              Latvian
                            </button>
                            <button
                              className='selectable-button border border-gray-300 rounded-lg p-2'
                              onClick={() =>
                                fetchDataByLanguageusermanual('FI')
                              }
                              data-language='Finnish'
                            >
                              Finnish
                            </button>
                          </div>

                          <div className='flex justify-end mt-6'>
                            {/* <button
                    className="px-4 py-2  text-white rounded hover:bg-blue-700 mr-2"
                    tyle={{ backgroundColor: "#009bb4" }}
                    onClick={()=>{
                      
                    
                      setShowModal(false);
                      
                    }}
                  > */}
                            {/* Confirm
                  </button> */}
                            <button
                              className='px-4 py-2 bg-white text-white rounded hover:bg-gray-500'
                              s
                              onClick={() => {
                                setshowModalusermanual(false);
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </Modal>
                      <button
                        className={`w-full h-12 xl:h-[73px] rounded-[10px] border border-white text-f_14_l_18 xl:text-f_22_l_28 font-supremeMedium uppercasee disabled:text-gray-500 disabled:cursor-not-allowed ${
                          workStatusData?.is_warranty
                            ? 'text-[#FFD009] border-[#FFD009]'
                            : ''
                        }`}
                        onClick={() => navigate('/service/Waranty')}
                        disabled={!workStatusData?.is_warranty}
                      >
                        {t('Warranty')}
                      </button>

                      {/* USER MANUAL */}
                      <button
                        className='w-full h-12 xl:h-[73px] rounded-[10px] border border-white text-f_14_l_18 xl:text-f_22_l_28 font-supremeMedium uppercase'
                        style={buttonStyle}
                        onClick={handleButtonClick}
                      >
                        {t('Bulletins')}
                      </button>
                      <button
                        className='w-full h-12 xl:h-[73px] rounded-[10px] border border-white text-f_14_l_18 xl:text-f_22_l_28 font-supremeMedium uppercasee'
                        onClick={() => navigate('/service/history')}
                      >
                        {t('History')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* RIGHT */}
              <div className='w-full flex flex-col md:w-1/2'>
                {/* LIST */}
                <div className='md:pl-5 xl:pl-[30px]'>
                  {/* BIKE NAME */}
                  <div className='mb-5 md:mb-[30px] xl:mb-14'>
                    <h3 className='bg-gradient-text text-f_19_l_24 md:text-f_20_l_25 xl:text-f_27_l_34 text-center font-supremeBold'>
                      {db_data?.model_name}, {db_data?.year}, {db_data?.color}
                    </h3>
                    {/* <button className="w-full mt-5 h-12 xl:h-[73px] rounded-[10px] border border-yellow text-f_14_l_18 xl:text-f_22_l_28 font-supremeMedium uppercase"onClick={() => navigate('/service/Waranty')}>
                    Warranty Registration
                    </button> */}
                    <Show
                      IF={
                        db_data?.warranty_end_date === undefined ||
                        db_data?.warranty_end_date === null
                      }
                    >
                      {isLoaded && !hasAssembling && (
                        <button
                          className='w-full mt-10 h-10 xl:h-[73px] rounded-[10px] border border-[#FF0000] text-[#FF0000] font-supremeMedium text-lg xl:text-xl disabled:border-gray-400 disabled:text-gray-400 disabled:cursor-not-allowed'
                          onClick={() => navigate('/service/Assembling')}
                        >
                          {t('assembling')}
                        </button>
                      )}
                      {isLoaded &&
                        hasAssembling &&
                        !hasWarrantyRegistration && (
                          <button
                            className='w-full mt-10 h-10 xl:h-[73px] rounded-[10px] border border-[#FFD009] text-[#FFD009] font-supremeMedium text-lg xl:text-xl disabled:border-gray-400 disabled:text-gray-400 disabled:cursor-not-allowed'
                            onClick={() =>
                              navigate('/vehicleData/warrantyregisterartion')
                            }
                          >
                            Warranty Registration
                          </button>
                        )}
                    </Show>
                    {warrantyModal && (
                      <div className='modal'>
                        <div className='modal-content'>
                          <span
                            className='close'
                            onClick={() => setWarrantyModal(false)}
                          >
                            &times;
                          </span>
                          <p>No warranty registration found.</p>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* LIST */}
                  <div className='space-y-3.5 xl:space-y-5'>
                    {/* new formation start */}
                    {/* {db_data?.status?.toString().length > 0 && (
                      <div className="flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23">
                        <span className="text-lightWhite uppercase">
                          Status
                        </span>
                        <span> {db_data?.status}</span>
                      </div>
                    )} */}
                    {db_data?.Manufacturer?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('Manufacturer')}
                        </span>
                        <span> {db_data?.Manufacturer}</span>
                      </div>
                    )}
                    {db_data?.series?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {' '}
                          {t('SERIES')}
                        </span>
                        <span>{db_data?.series}</span>
                      </div>
                    )}
                    {db_data?.Factory_name?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('FACTORY_NAME')}
                        </span>
                        <span>{db_data?.Factory_name}</span>
                      </div>
                    )}
                    {db_data?.model_name?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('MODEL_NAME')}
                        </span>
                        <span>{db_data?.model_name}</span>
                      </div>
                    )}
                    {db_data?.eu_type?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('EU_TYPE_APPROVAL')}
                        </span>
                        <span>{db_data?.eu_type}</span>
                      </div>
                    )}
                    {db_data?.steering_power?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('STEERING_POWER')}
                        </span>
                        <span>{db_data?.steering_power}</span>
                      </div>
                    )}
                    {db_data?.wheels?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {' '}
                          {t('WHEELS')}
                        </span>
                        <span>{db_data?.wheels}</span>
                      </div>
                    )}
                    {db_data?.color?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {' '}
                          {t('COLOR')}
                        </span>
                        <span>{db_data?.color}</span>
                      </div>
                    )}
                    {db_data?.lights?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('LIGHTS')}
                        </span>
                        <span>{db_data?.lights}</span>
                      </div>
                    )}
                    {db_data?.screen?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('SCREEN')}
                        </span>
                        <span>{db_data?.screen}</span>
                      </div>
                    )}
                    {db_data?.cargo_compartment?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('CARGO_COMPARTMENTS')}
                        </span>
                        <span>{db_data?.cargo_compartment}</span>
                      </div>
                    )}
                    {db_data?.communication_terminal?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('COMMUNICATION_TERMINAL')}
                        </span>
                        <span>{db_data?.communication_terminal}</span>
                      </div>
                    )}
                    {db_data?.engine_name?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('Engine_Name')}
                        </span>
                        <span>{db_data?.engine_name}</span>
                      </div>
                    )}
                    {db_data?.engine_number?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('Engine_Number')}
                        </span>
                        <span>{db_data?.engine_number}</span>
                      </div>
                    )}
                    {db_data?.country?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {' '}
                          {t('COUNTRY')}
                        </span>
                        <span>{db_data?.country}</span>
                      </div>
                    )}
                    {/* {db_data?.sku?.toString().length > 0 && (
                      <div className="flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23">
                        <span className="text-lightWhite uppercase">SKU</span>
                        <span> {db_data?.sku}</span>
                      </div>
                    )} */}
                    {db_data?.year?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('REGISTRATION_YEAR')}
                        </span>
                        <span> {db_data?.year}</span>
                      </div>
                    )}
                    {db_data?.owner_company?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          Owner company
                        </span>
                        <span> {db_data?.owner_company}</span>
                      </div>
                    )}

                    {db_data?.owner_name?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('OWNER_NAME')}
                        </span>
                        <span>{db_data?.owner_name}</span>
                      </div>
                    )}
                    {/* ORDERER EMAIL */}
                    {db_data?.owner_email?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('OWNER_EMAIL')}
                        </span>
                        <span>{db_data?.owner_email}</span>
                      </div>
                    )}
                    {/* ORDERER PHONE */}
                    {db_data?.owner_phone?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('OWNER_PHONE')}
                        </span>
                        <span>{db_data?.owner_phone}</span>
                      </div>
                    )}

                    {db_data?.plate_number?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('Plate_number')}
                        </span>
                        <span>{db_data?.plate_number}</span>
                      </div>
                    )}
                    {db_data?.vin_code?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('VIN_CODE')}
                        </span>
                        <span>{db_data?.vin_code}</span>
                      </div>
                    )}
                    {/* {db_data?.Production_date?.toString().length > 0 && (
                      <div className="flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23">
                        <span className="text-lightWhite uppercase">
                          Production date
                        </span>
                        <span>{db_data?.Production_date}</span>
                      </div>
                    )}
                    {db_data?.date_of_manufacture?.toString().length > 0 && (
                      <div className="flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23">
                        <span className="text-lightWhite uppercase">
                          Factory out date
                        </span>
                        <span>{db_data?.date_of_manufacture}</span>
                      </div>
                    )} */}
                    {db_data?.ecu_map?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          Ecu map
                        </span>
                        <span>{db_data?.ecu_map}</span>
                      </div>
                    )}
                    {/* {db_data?.bar_code?.toString().length > 0 && (
                      <div className="flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23">
                        <span className="text-lightWhite uppercase">
                          Bar code
                        </span>
                        <span>{db_data?.bar_code}</span>
                      </div>
                    )} */}
                    {db_data?.warranty_end_date?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('Warranty_end_date')}
                        </span>
                        <span>
                          {new Date(db_data?.warranty_end_date) <=
                          new Date('2000-01-01')
                            ? 'Expired'
                            : db_data?.warranty_end_date}
                        </span>
                      </div>
                    )}
                    {db_data?.warranty_ext_end_date?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          Warranty ext end date
                        </span>
                        <span>
                          {new Date(db_data?.warranty_ext_end_date) <=
                          new Date('2000-01-01')
                            ? 'Expired'
                            : db_data?.warranty_ext_end_date}
                        </span>
                      </div>
                    )}
                    {companyData?.company?.company_name.toString().length >
                      0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('DEALER')}
                        </span>
                        <span>{companyData?.company?.company_name}</span>
                      </div>
                    )}

                    {/* new formation end */}

                    {/* SKU */}

                    {/* VIN CODE / PRODUCT ID */}

                    {/* COUNTRY */}

                    {/* SERIES */}

                    {/* MODEL NAME */}

                    {/* FACTORY NAME */}

                    {/* COLOR */}

                    {/* EU TYPE APPROVAL */}

                    {/* BODY TYPE */}
                    {db_data?.body_type?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          BODY TYPE
                        </span>
                        <span>{db_data?.body_type}</span>
                      </div>
                    )}

                    {/* STEERING POWER */}

                    {/* WHEELS */}

                    {/* SCREEN */}

                    {/* LIGHTS */}

                    {/* CARGO COMPARTMENTS */}

                    {/* COMMUNICATION TERMINAL */}

                    {/* DATA OF MANUFACTURE */}

                    {/* ORDERER */}

                    {/* IMPORTER */}
                    {db_data?.importer?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('IMPORTER')}
                        </span>
                        <span>{db_data?.importer}</span>
                      </div>
                    )}

                    {/* DEALER */}
                    {db_data?.dealer?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('DEALER')}
                        </span>
                        <span>{db_data?.dealer}</span>
                      </div>
                    )}
                  </div>
                </div>

                {/* BUTTONS MOBILE */}
                <div className='md:hidden mt-6'>
                  <div className='flex flex-col gap-3.5 items-center'>
                    <div className='w-full grid grid-cols-2 gap-3.5'>
                      {/* MAINTENANCE */}
                      <button
                        className={`w-full h-12 rounded-[10px] border border-white text-f_14_l_18 font-supremeMedium uppercase disabled:text-gray-500 disabled:cursor-not-allowed ${
                          workStatusData?.is_maintenance
                            ? 'text-[#FFD009] border-[#FFD009]'
                            : ''
                        }`}
                        onClick={() => handlemainButtonClick()}
                        disabled={!workStatusData?.is_maintenance}
                      >
                        {t('Maintenance')}
                      </button>

                      {/* REPAIRING */}
                      <button
                        className={`w-full h-12 rounded-[10px] border border-white text-f_14_l_18 font-supremeMedium uppercase disabled:text-gray-500 disabled:cursor-not-allowed ${
                          workStatusData?.is_repair
                            ? 'text-[#FFD009] border-[#FFD009]'
                            : ''
                        }`}
                        onClick={() => navigate('/service/maintainence')}
                        disabled={!workStatusData?.is_repair}
                      >
                        {t('Repairing')}
                      </button>

                      {/* OEM PARTS */}
                      <button
                        className='w-full h-12 rounded-[10px] border border-white text-f_14_l_18 font-supremeMedium uppercase'
                        onClick={() =>
                          window.open(
                            `https://cfmoto.motohobi.ee/product/${db_data?.year}-${db_data?.series}-${db_data?.Factory_name}`,
                            '_blank'
                          )
                        }
                      >
                        {t('Parts')}
                      </button>
                      {localStorage.getItem('show_accessories') === 'true' && (
                        <button
                          className='w-full h-12 rounded-[10px] border border-white text-f_14_l_18 font-supremeMedium uppercase'
                          onClick={() =>
                            window.open(
                              `https://cfmoto.motohobi.ee/product/${db_data?.year}-accessory-${db_data?.Factory_name}`,
                              '_blank'
                            )
                          }
                        >
                          {t('Accessories')}
                        </button>
                      )}

                      {/* OEM ACCESSORIES */}

                      {/* SERVICE MANUAL */}
                      <button
                        className='w-full h-12 rounded-[10px] border border-white text-f_14_l_18 font-supremeMedium uppercase'
                        onClick={() => {
                          // setShowModal2(true)
                          fetchDataByLanguage('EN');
                        }}
                      >
                        {t('Service_manual')}
                      </button>

                      {/* USER MANUAL */}
                      <button
                        className='w-full h-12 rounded-[10px] border border-white text-f_14_l_18 font-supremeMedium uppercase'
                        onClick={() => {
                          setshowModalusermanual(true);
                        }}
                      >
                        {t('User_manual')}
                      </button>

                      <button
                        className={`w-full h-12 rounded-[10px] border border-white text-f_14_l_18 font-supremeMedium uppercase disabled:text-gray-500 disabled:cursor-not-allowed ${
                          workStatusData?.is_warranty
                            ? 'text-[#FFD009] border-[#FFD009]'
                            : ''
                        }`}
                        onClick={() => navigate('/service/Waranty')}
                        disabled={!workStatusData?.is_warranty}
                      >
                        {t('Warranty')}
                      </button>

                      {/* USER MANUAL */}
                      <button className='w-full h-12 rounded-[10px] border border-white text-f_14_l_18 font-supremeMedium uppercase'>
                        {t('Bulletins')}
                      </button>
                      {/* mobile history */}
                      <button
                        className='w-full h-12 rounded-[10px] border border-white text-f_14_l_18 font-supremeMedium uppercase'
                        onClick={() => navigate('/service/history')}
                      >
                        {t('History')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* HISTORY SECTION */}
            {/*  */}
          </div>
        </main>
      </div>
    </>
  );
}

export default VehicleData;
