import axios from 'axios';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../components/Common/Header';
import Sidebar from '../components/Common/Sidebar';
import placeholder_img from '../images/camera_img.svg';

function AsembelingAccesories() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [selectedCards, setSelectedCards] = useState([]);
  const data = localStorage.getItem('vinnData');
  const [showModal, setShowModal] = useState(false);
  const profile_id = localStorage.getItem('profile_id');
  const parsed_data = JSON.parse(data);
  const db_data = parsed_data?.data;
  const vin = localStorage.getItem('vinnData');
  const product_id = db_data?.id;
  const dateStr = db_data?.date_of_manufacture;
  const dateObj = new Date(dateStr);
  const year = dateObj.getFullYear();
  // console.log("db_data+++++>> ",db_data)
  const image_url = `${db_data?.image}`;
  // const [openSidebar, setOpenSidebar] = useState(false)
  const [comment, setComment] = useState('');
  const [selectedFile5, setSelectedFile5] = useState(null);
  const [selectedFile6, setSelectedFile6] = useState(null);
  const [loading, setLoading] = useState(true);
  const [prevtime, setprevtime] = useState('');
  const [responseData, setResponseData] = useState(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [selectedFile4, setSelectedFile4] = useState(null);

  const [selectedItems, setSelectedItems] = useState([]);

  const [spareParts, setSpareParts] = useState([]);
  const [files, setFiles] = useState([null, null, null, null]);
  const [elapsedTime, setElapsedTime] = useState(0);

  const timers = useRef([]);
  const [services, setServices] = useState([]);
  const [fillvalue, setfillValue] = useState({});
  const [valvalue, setvalValue] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentItem = services[currentIndex];
  ///////
  const [isYesChecked, setIsYesChecked] = useState({});
  const [isChecked, setIsChecked] = useState({});
  const [textValue, setTextValue] = useState({});
  const [available, setAvailable] = useState(false);
  const [mentainence, setMentainance] = useState({});
  const timerRefm = useRef(0);
  const timerRef = useRef([]);
  const [timer, setTimer] = useState({});
  const [disabledIndices, setDisabledIndices] = useState([]);
  const [timerValue, setTimerValue] = useState(0);
  const [timerRunning, setTimerRunning] = useState(false);

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const fileInputRef4 = useRef(null);

  const fileInputRef5 = useRef(null);
  const fileInputRef6 = useRef(null);
  const [temptime, setTemp] = useState({});
  const [dataPost, setDataPost] = useState({
    // maintanence_id: 3,
    product_id: 3,
    mileage: 0,
    cause: '',
    review: '',
    remarks: '',
    failure_description: '',

    replace_parts: [2],
  });
  async function urlToFile(url, filename, mimeType) {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const blob = await response.blob();
    const file = new File([blob], filename, { type: mimeType });
    return file;
  }
  const handleTemporaryRepairButtonClick = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/temporarywarranty/${db_data.id}/`
      );

      if (response.data /* Check if data is as expected */) {
        const responseData = response.data;

        let pictureFile = '';
        if (responseData.picture) {
          try {
            const pictureUrl = `${process.env.REACT_APP_API_URL}${responseData.picture}`;
            pictureFile = await urlToFile(
              pictureUrl,
              'picture.png',
              'image/png'
            );
          } catch (error) {
            console.error('Error in converting URL to File: ', error);
          }
        }

        setDataPost({
          ...dataPost,
          product_id: responseData.product_id || 3,
          mileage: responseData.mileage || 0,
          cause: responseData.cause || '',
          review: responseData.review || '',
          remarks: responseData.remarks || '',
          failure_description: responseData.failure_description || '',
          picture: pictureFile || '',
        });

        setprevtime(responseData.time || '');
        setResponseData(responseData);
        setSelectedFile5(
          `${process.env.REACT_APP_API_URL}${responseData.picture || ''}`
        );
        setTemp(responseData.time);

        // Navigate to '/service/temporary-repair'
        // navigate('/service/temporary-repair');
      } else {
        // Show alert if no data
        toast.error('No data found', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  // const handleTemporaryRepairButtonClick = async () => {
  //     try {
  //       const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/temporarywarranty/${db_data.id}/`);

  //       if (response.data /* Check if data is as expected */) {
  //         const responseData = response.data;

  //         setDataPost({
  //           ...dataPost,
  //           product_id: responseData.product_id || 3, // Update product_id if available
  //           mileage: responseData.mileage || 0,
  //           cause: responseData.cause || "",
  //           review: responseData.review || "",
  //           remarks: responseData.remarks || "",
  //           failure_description: responseData.failure_description || "",
  //           picture:responseData.picture || ""
  //           // You can update more fields as needed
  //         });

  //         setprevtime(responseData.time || "");
  //         setResponseData(responseData);
  //         setSelectedFile5(`${process.env.REACT_APP_API_URL}${responseData.picture || ""}`);
  //         setTemp(responseData.time);

  //         // Navigate to '/service/temporary-repair'
  //         // navigate('/service/temporary-repair');
  //       } else {
  //         // Show alert if no data
  //         toast.error('No data found', {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "colored",
  //         });
  //       }
  //     } catch (error) {
  //       console.error('Error fetching data:', error);

  //     }
  //   };

  const getProductImages = () => {
    const res = axios
      .get(
        // `${process.env.REACT_APP_API_URL}/product-service/create/`,
        `${process.env.REACT_APP_API_URL}/api/service_image/${db_data?.id}/`
      )
      .then((res) => {
        if (res?.data && res.data.length > 0) {
          const data = res.data[0];

          if (data.image1) {
            setSelectedFile1(`${process.env.REACT_APP_API_URL}/${data.image1}`);
          }

          if (data.image2) {
            setSelectedFile2(`${process.env.REACT_APP_API_URL}/${data.image2}`);
          }

          if (data.image3) {
            setSelectedFile3(`${process.env.REACT_APP_API_URL}/${data.image3}`);
          }

          if (data.image4) {
            setSelectedFile4(`${process.env.REACT_APP_API_URL}/${data.image4}`);
          }
        }
      });
  };
  const countUp = () => {
    timerRefm.current = timerRefm.current + 1;
  };
  //   useEffect(() => {
  //     // Assuming you have the data available locally or through an alternative method
  //     handleTemporaryRepairButtonClick();
  //     setLoading(false);

  //     // You can call your other functions or set your state variables here as needed
  //     setInterval(countUp, 1000);
  //     getProductImages();
  //     getSpareParts();
  //     // Replace the next three lines with your data population logic using the available data
  //     // setMentainance(/* Replace with your data */);
  //     // // setDataPost(
  //     //           {
  //     //             ...dataPost,
  //     //             maintanence_id: res?.data?.id,
  //     //             product_id: res?.data?.product_id,
  //     //           }
  //     //         )
  //     setAvailable(true);
  //   }, []);

  const handleCardClick = (cardId, item) => {
    if (selectedCards.includes(cardId)) {
      // If card is already selected, remove it from selectedCards
      setSelectedCards(selectedCards.filter((id) => id !== cardId));
      setSelectedItems(selectedItems.filter((item) => item?.id !== cardId));
    } else {
      // If card is not selected, add it to selectedCards
      setSelectedCards([...selectedCards, cardId]);
      setSelectedItems([...selectedItems, item]);
    }
  };

  const handleImageClick = (ref) => {
    console.log('image pciker ', ref);
    ref.current.click();
  };
  const handleImageClick2 = (ref) => {
    console.log('image picker', ref);

    // Check if the device is a mobile device
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile) {
      // Mobile device - Ask the user for their choice
      if (
        window.confirm(
          "Do you want to take a new photo? Click 'OK' for camera, 'Cancel' for gallery."
        )
      ) {
        // User chooses to take a new photo
        ref.current.setAttribute('capture', 'environment');
      } else {
        // User chooses to pick an existing photo
        ref.current.removeAttribute('capture');
      }
    } else {
      // Non-mobile device - Standard file input behavior
      ref.current.removeAttribute('capture');
    }

    // Trigger the file input
    ref.current.click();
  };

  const handleFileSelect1 = (event) => {
    setSelectedFile1(URL.createObjectURL(event.target.files[0]));
    files[0] = event.target.files[0];
    setFiles([...files]);
  };

  const handleFileSelect5 = (event) => {
    setSelectedFile5(URL.createObjectURL(event.target.files[0]));

    setDataPost({
      ...dataPost,
      picture: event.target.files[0],
    });
  };

  const handleFileSelect6 = (event) => {
    setSelectedFile6(URL.createObjectURL(event.target.files[0]));

    setDataPost({
      ...dataPost,
      video: event.target.files[0],
    });
  };

  const handleFileSelect2 = (event) => {
    setSelectedFile2(URL.createObjectURL(event.target.files[0]));
    files[1] = event.target.files[0];
    setFiles([...files]);
  };

  const handleFileSelect3 = (event) => {
    setSelectedFile3(URL.createObjectURL(event.target.files[0]));
    files[2] = event.target.files[0];
    setFiles([...files]);
  };

  const handleFileSelect4 = (event) => {
    setSelectedFile4(URL.createObjectURL(event.target.files[0]));
    files[3] = event.target.files[0];
    setFiles([...files]);
  };

  //   const handleSubmit = async (e) => {
  //     if (comment.length>0 && files.length>0 ) {

  //     try {
  //       await saveServices(e);
  //     } catch (error) {
  //       console.log("Error in saveServices:", error);
  //       alert("Error: saveServices failed!");
  //       return;
  //     }

  //     try {
  //       await postImgs(e);
  //     } catch (error) {
  //       console.log("Error in postImgs:", error);
  //       alert("Error: postImgs failed!");
  //       return;
  //     }

  //       await cardData(e);

  //     e.preventDefault()
  //     const post = { product: product_id, note: comment }
  //     if (comment.length==0) {
  //       alert("Mechenical comments is empty!")
  //       return
  //     }else{
  //       try {
  //         const res = await axios.post(
  //           `${process.env.REACT_APP_API_URL}/mechanical-notes/create/`,
  //           post
  //         )

  //         var formdata = new FormData()
  //         formdata.append("description", "Images and comments have been created.")
  //         formdata.append(
  //           "estonian_description",
  //           `Pildid ja kommentaarid on loodud. `
  //         )
  //         formdata.append(
  //           "latvian_description",
  //           `Attēli un komentāri ir izveidoti `
  //         )
  //         formdata.append(
  //           "finnish_description",
  //           `Kuvat ja kommentit on luotu. `
  //         )
  //         formdata.append("historical_note", ``)
  //         formdata.append("vehicle", db_data?.vehicle_id)
  //         formdata.append("owner_email", db_data?.orderer_email)
  //         formdata.append("plate_number", db_data?.plate_number)
  //         formdata.append("vin_code", db_data?.vin_code)

  //         var requestOptions = {
  //           method: "POST",
  //           body: formdata,
  //           redirect: "follow",
  //         }
  //         fetch(
  //           `${process.env.REACT_APP_API_URL}/history/create/`,
  //           requestOptions
  //         )
  //           .then((response) => response.text())
  //           .then((result) => console.log(result))
  //           .catch((error) => console.log("error", error))

  //         alert("Images, comments and history has been created")
  //         navigate("/vehicleData")

  //         if (res) {
  //           setComment("")
  //         }
  //       } catch (e) {
  //         alert(e)
  //       }
  //     }
  //   }
  //   else{
  //     toast.error(
  //       `Please fill all Fields`
  //       , {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "colored",
  //       });
  //   }

  //   }

  return (
    <div className='flex'>
      <Sidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />

      <Header setOpenSidebar={setOpenSidebar} />

      <main className='md:ml-[16.25rem] xl:ml-[21.125rem] pt-[62px] md:pt-[6.5625rem] w-full'>
        {/* VEHICLE DATA */}
        <div className='px-[26px] md:pl-9 xl:pl-[3.25rem] md:pr-10 pt-[22px] md:pt-[42px] xl:pt-14 pb-11 xl:pb-16'>
          <div className='flex flex-col md:flex-row'>
            {/* LEFT */}
            <div className='w-full md:w-1/2 md:pt-3 xl:pt-12'>
              {/* IMAGE */}
              <div className='mb-10 md:mb-20'>
                <img src={image_url} alt='bike-img' />
              </div>
            </div>

            {/* RIGHT */}
            <div className='w-full flex flex-col md:w-1/2'>
              {/* LIST */}
              <div className='md:pl-5 xl:pl-[30px]'>
                {/* BIKE NAME */}
                <div className='mb-5 md:mb-[30px] xl:mb-14'>
                  <h3 className='bg-gradient-text text-f_19_l_24 md:text-f_20_l_25 xl:text-f_27_l_34 text-center font-supremeBold'>
                    {db_data?.model_name}, {db_data?.year}, {db_data?.color}
                  </h3>
                  {/* <button className="w-full mt-5 h-12 xl:h-[73px] rounded-[10px] border border-yellow text-f_14_l_18 xl:text-f_22_l_28 font-supremeMedium uppercase"onClick={() => navigate('/service/Waranty')}>
                Warranty Registration
                </button> */}
                </div>

                {/* LIST */}
                <div className='space-y-3.5 xl:space-y-5'>
                  {/* SKU */}
                  {db_data?.sku?.toString().length > 0 && (
                    <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                      <span className='text-lightWhite uppercase'>SKU</span>
                      <span> {db_data?.sku}</span>
                    </div>
                  )}

                  {/* VIN CODE / PRODUCT ID */}
                  {db_data?.vin_code?.toString().length > 0 && (
                    <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                      <span className='text-lightWhite uppercase'>
                        {t('VIN_CODE')}
                      </span>
                      <span>{db_data?.vin_code}</span>
                    </div>
                  )}

                  {/* COUNTRY */}
                  {db_data?.country?.toString().length > 0 && (
                    <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                      <span className='text-lightWhite uppercase'>
                        {' '}
                        {t('COUNTRY')}
                      </span>
                      <span>{db_data?.country}</span>
                    </div>
                  )}

                  {/* SERIES */}
                  {db_data?.series?.toString().length > 0 && (
                    <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                      <span className='text-lightWhite uppercase'>
                        {' '}
                        {t('SERIES')}
                      </span>
                      <span>{db_data?.series}</span>
                    </div>
                  )}

                  {/* MODEL NAME */}
                  {db_data?.model_name?.toString().length > 0 && (
                    <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                      <span className='text-lightWhite uppercase'>
                        {t('MODEL_NAME')}
                      </span>
                      <span>{db_data?.model_name}</span>
                    </div>
                  )}

                  {/* FACTORY NAME */}
                  {db_data?.Factory_name?.toString().length > 0 && (
                    <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                      <span className='text-lightWhite uppercase'>
                        {t('FACTORY_NAME')}
                      </span>
                      <span>{db_data?.Factory_name}</span>
                    </div>
                  )}

                  {/* COLOR */}
                  {db_data?.color?.toString().length > 0 && (
                    <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                      <span className='text-lightWhite uppercase'>
                        {' '}
                        {t('COLOR')}
                      </span>
                      <span>{db_data?.color}</span>
                    </div>
                  )}

                  {/* EU TYPE APPROVAL */}
                  {db_data?.eu_type_approval?.toString().length > 0 && (
                    <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                      <span className='text-lightWhite uppercase'>
                        {t('EU_TYPE_APPROVAL')}
                      </span>
                      <span>{db_data?.eu_type_approval}</span>
                    </div>
                  )}

                  {/* BODY TYPE */}
                  {db_data?.body_type?.toString().length > 0 && (
                    <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                      <span className='text-lightWhite uppercase'>
                        BODY TYPE
                      </span>
                      <span>{db_data?.body_type}</span>
                    </div>
                  )}

                  {/* STEERING POWER */}
                  {db_data?.steering_power?.toString().length > 0 && (
                    <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                      <span className='text-lightWhite uppercase'>
                        {t('STEERING_POWER')}
                      </span>
                      <span>{db_data?.steering_power}</span>
                    </div>
                  )}

                  {/* WHEELS */}
                  {db_data?.wheels?.toString().length > 0 && (
                    <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                      <span className='text-lightWhite uppercase'>
                        {' '}
                        {t('WHEELS')}
                      </span>
                      <span>{db_data?.wheels}</span>
                    </div>
                  )}

                  {/* SCREEN */}
                  {db_data?.screen?.toString().length > 0 && (
                    <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                      <span className='text-lightWhite uppercase'>
                        {t('SCREEN')}
                      </span>
                      <span>{db_data?.screen}</span>
                    </div>
                  )}

                  {/* LIGHTS */}
                  {db_data?.lights?.toString().length > 0 && (
                    <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                      <span className='text-lightWhite uppercase'>
                        {t('LIGHTS')}
                      </span>
                      <span>{db_data?.lights}</span>
                    </div>
                  )}

                  {/* CARGO COMPARTMENTS */}
                  {db_data?.cargo_compartments?.toString().length > 0 && (
                    <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                      <span className='text-lightWhite uppercase'>
                        {t('CARGO_COMPARTMENTS')}
                      </span>
                      <span>{db_data?.cargo_compartments}</span>
                    </div>
                  )}

                  {/* COMMUNICATION TERMINAL */}
                  {db_data?.communication_terminal?.toString().length > 0 && (
                    <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                      <span className='text-lightWhite uppercase'>
                        {t('COMMUNICATION_TERMINAL')}
                      </span>
                      <span>{db_data?.communication_terminal}</span>
                    </div>
                  )}

                  {/* DATA OF MANUFACTURE */}
                  {db_data?.date_of_manufacture?.toString().length > 0 && (
                    <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                      <span className='text-lightWhite uppercase'>
                        DATA OF MANUFACTURE
                      </span>
                      <span>{db_data?.date_of_manufacture}</span>
                    </div>
                  )}

                  {/* ORDERER */}
                  {db_data?.orderer?.toString().length > 0 && (
                    <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                      <span className='text-lightWhite uppercase'>
                        {t('OWNER_NAME')}
                      </span>
                      <span>{db_data?.orderer}</span>
                    </div>
                  )}

                  {/* ORDERER PHONE */}
                  {db_data?.orderer_phone?.toString().length > 0 && (
                    <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                      <span className='text-lightWhite uppercase'>
                        {t('OWNER_PHONE')}
                      </span>
                      <span>{db_data?.orderer_phone}</span>
                    </div>
                  )}

                  {/* ORDERER EMAIL */}
                  {db_data?.orderer_email?.toString().length > 0 && (
                    <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                      <span className='text-lightWhite uppercase'>
                        {t('OWNER_EMAIL')}
                      </span>
                      <span>{db_data?.orderer_email}</span>
                    </div>
                  )}

                  {/* IMPORTER */}
                  {db_data?.importer?.toString().length > 0 && (
                    <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                      <span className='text-lightWhite uppercase'>
                        {t('IMPORTER')}
                      </span>
                      <span>{db_data?.importer}</span>
                    </div>
                  )}

                  {/* DEALER */}
                  {db_data?.dealer?.toString().length > 0 && (
                    <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                      <span className='text-lightWhite uppercase'>
                        {t('DEALER')}
                      </span>
                      <span>{db_data?.dealer}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='mb-7 mt-7'>
            <h3 className='text-f_22_l_28 md:text-f_27_l_34 font-supremeBold mb-[25px] md:mb-5'>
              {/* {t("Failure_description") } */}
              Accessories added
            </h3>

            <textarea
              value={dataPost?.failure_description}
              // onChange={(event)=>{
              //   onChangeValue(event,"failure_description")
              // }}
              className='h-[169px] border border-white-100 rounded-[10px] bg-transparent w-full'
            />
          </div>
          <div className='mb-[67px] md:mb-[41px] xl:mb-[90px]'>
            <div className='grid grid-cols-2 xl:grid-cols-4 gap-5 md:gap-y-[27px] md:gap-x-[30px]'>
              {/* IMAGE 1 */}
              <div
                className='w-full h-[156px] md:h-[196px] xl:h-[168px] bg-contain bg-no-repeat bg-center flex justify-center items-center bg-black-500 rounded-[10px]'
                style={{
                  backgroundImage: selectedFile5
                    ? `url(${selectedFile5})`
                    : 'none',
                  backgroundSize: 'cover', // Stretch the background image to cover the container
                  backgroundPosition: 'center',
                }}
                onClick={(e) => handleImageClick2(fileInputRef5)}
              >
                {!selectedFile1 && (
                  <div className='flex flex-col justify-center items-center cursor-pointer'>
                    <img src={placeholder_img} alt='add-img' />
                    <div className=' text-f_18_l_28 mt-3.5'>
                      {t('add_photo')}
                    </div>
                  </div>
                )}
              </div>
              {/* INPUT */}
              <input
                type='file'
                ref={fileInputRef5}
                style={{ visibility: false, display: 'none' }}
                accept='image/png, image/jpeg, image/jpg'
                onChange={handleFileSelect2}
              />
            </div>
          </div>

          <div className='mb-7'>
            <h3 className='text-f_22_l_28 md:text-f_27_l_34 font-supremeBold mb-[25px] md:mb-5'>
              {/* {t("Failure_description") } */}
              {t('mechanic_comment')}:
            </h3>

            <textarea
              value={dataPost?.failure_description}
              // onChange={(event)=>{
              //   onChangeValue(event,"failure_description")
              // }}
              className='h-[169px] border border-white-100 rounded-[10px] bg-transparent w-full'
            />
          </div>
          <button
            className='w-[240px] h-[52px] md:h-[62px] bg-gradient rounded-[10px] text-f_16_l_20 md:text-f_22_l_28 font-supremeMedium'
            onClick={(event) => {
              //   saveWarranty();
              event.target.style.display = 'none';
            }}
          >
            {/* {t("Save_warranty_data") } */}
            Completed
          </button>
        </div>
      </main>
    </div>
  );
}

export default AsembelingAccesories;
