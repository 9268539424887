export function AddValueAndLabel(fetchedData = [], firstValue, secondValue) {
  const updatedList = fetchedData?.map((item) => ({
    ...item,
    value: item[firstValue].toString(),
    label: item[secondValue]
      ? item[secondValue].toString()
      : item[firstValue].toString(),
  }));
  return updatedList;
}
