import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import {
  Link,
  NavLink,
  redirect,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import i18n from '../../i18n';
import dashboard_icon from '../../images/dashboard_icon.svg';
import dashboard_icon_active from '../../images/dashboard_icon_white.svg';
import et from '../../images/et.svg';
import fn from '../../images/fn.svg';
import logo from '../../images/logo.svg';
import lv from '../../images/lv.svg';
import us from '../../images/us.svg';
import vehicle_icon from '../../images/vehicle_icon.svg';
import workicon1_active from '../../images/work1.svg';
import workicon2_active from '../../images/work222222.svg';
import MobileLanguageSwitcher from './mobileviewlanguageswither.jsx';

function Sidebar(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeLink, setActiveLink] = useState('');
  const [activeVehiclelink, setActiveVehiclelink] = useState('');
  const [showModal, setShowModal] = useState(false);
  const data = localStorage.getItem('vinnData');
  const permis = localStorage.getItem('show_accessories');
  // console.log("--efdgffgfdgdffhfhfgf", permis);
  // const showaccessories=localStorage.getItem("show_accessories")
  const parsed_data = JSON.parse(data);
  const db_data = parsed_data?.data;
  // console.log("--efdgffgfdgdffhfhfgf", db_data);
  const dateStr = db_data?.date_of_manufacture;

  const dateObj = new Date(dateStr);
  const year = dateObj.getFullYear();
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModalusermanual, setshowModalusermanual] = useState(false);
  const [workStatusData, setWorkStatusData] = useState(null);

  async function logout_function() {
    const userEmail = localStorage.getItem('profile_email');
    const vinnData = JSON.parse(localStorage.getItem('vinnData'));

    if (vinnData && vinnData.data && vinnData.data.vin_code) {
      const vinCode = vinnData.data.vin_code;
      await fetch(
        `${process.env.REACT_APP_API_URL}/vehicles/logout_vehicle/${userEmail}/?Vehicle_servicing=${vinCode}`
      );
    }
    redirect('/');
  }

  const handleLinkClick = (link) => {
    if (props.onItemClick) {
      props.onItemClick();
    }
    setActiveLink(link);
  };
  const handleVehicleLinkClick = (link) => {
    setActiveVehiclelink(link.toString());
  };

  // const isVehicleActive = activeVehiclelink === link.id ? "text-gray-200" : "text-gray-600"
  // LINKS TOP
  const linksTop = [
    {
      id: 'dashboard',
      text: t('Dashboard'),
      icon: dashboard_icon,
      activeIcon: dashboard_icon_active,
      goToLink: '/dashboard',
    },
    // {
    //   id: "service",
    //   text: "Service",
    //   icon: service_icon,
    //   activeIcon: service_icon_active,
    // },
    // {
    //   id: "order",
    //   text: "Order",
    //   icon: order_icon,
    //   activeIcon: order_icon,
    // },
    // {
    //   id: "bulletins",
    //   text: "Bulletins",
    //   icon: bulletins_icon,
    //   activeIcon: bulletins_icon,
    // },
    // // {
    // //   id: "warranty",
    // //   text: "Warranty",
    // //   icon: warranty_icon,
    // //   activeIcon: warranty_icon,
    // // },
    // {
    //   id: "help",
    //   text: "Help",
    //   icon: help_icon,
    //   activeIcon: help_icon,
    // },
    {
      id: 'vehicle',
      text: t('Vehicle'),
      icon: vehicle_icon,
      activeIcon: vehicle_icon,
      goToLink: localStorage.getItem('vinnData') ? '/vehicleData' : '/vin',
    },
  ];
  function downloadPdfFile(pdfUrl) {
    // Create an anchor element
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.target = '_blank';
    link.download = 'your_file_name.pdf'; // Set the desired file name here

    // Trigger a click event on the anchor element
    link.click();
  }
  const fetchDataByLanguage = (language) => {
    // setShowModal3(false);

    // Construct the API URL using the selected language and db_data
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/filter_service_manual/${language}/${db_data?.Factory_name}/`;

    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log('Fetched data:', data);
        if (data.service_manual_pdf) {
          // Check if data is not null and user_manual_pdf exists
          // Download the PDF file
          downloadPdfFile(`${data.service_manual_pdf}`);
        } else {
          toast.error('No Service manual found', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        alert('Error fetching data. Please try again later.');
      });
  };
  const fetchDataByLanguageusermanual = (language) => {
    setshowModalusermanual(false);

    // Construct the API URL using the selected language and db_data
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/filter_user_manual/${language}/${db_data?.Factory_name}/`;

    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log('Fetched data:', data);
        if (data.user_manual_pdf) {
          // Check if data is not null and user_manual_pdf exists
          // Download the PDF file
          downloadPdfFile(`${data.user_manual_pdf}`);
        } else {
          toast.error('No user manual found', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        alert('Error fetching data. Please try again later.');
      });
  };
  // const fetchDataByLanguage = (language) => {
  //   setShowModal3(false)

  //   // Construct the API URL using the selected language and db_data
  //   const apiUrl = `${process.env.REACT_APP_API_URL}/api/filter_service_manual/${language}/${db_data?.id}/`;
  //   // setShowModal(false);
  //   // Make the API call using the apiUrl
  //   fetch(apiUrl)
  //     .then((response) => {
  //       if (!response.ok) {
  //         // Handle the case where the API request fails
  //         throw new Error('Network response was not ok');
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       console.log("--------ssss------------",data);
  //       if (data?.service_manual_pdf) {

  //         // Handle the API response data here
  //         console.log("--------ssss------------",data);

  //         downloadPdfFile(`${process.env.REACT_APP_API_URL}/${data?.service_manual_pdf}`)
  //       } else {
  //         // If data is empty, show an alert
  //         toast.error('No Service manual found', {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "colored",
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data:', error);
  //       // Handle any other errors that occur during the request
  //       alert('Error fetching data. Please try again later.');
  //     });
  // };
  const vehicleMenu = [
    {
      id: 'AddWork',
      text: `${t('add')} ${t('work')}`,
      goToLink: '/service/Addwork',
      // disabled: !workStatusData?.is_maintenance,
    },

    {
      id: 'Logout',
      text: t('Log_out'),
      goToLink: async () => {
        await logout_function();
        window.location.href = '/service/logout';
      },
    },
  ];
  const workMenu = [
    {
      id: 'Add',
      text: t('add'),
      icon: workicon1_active,
      activeIcon: workicon1_active,
      goToLink: '/service/Addwork',
    },

    {
      id: 'Pending',
      text: t('pending'),
      icon: workicon2_active,
      activeIcon: workicon2_active,
      goToLink: '',
    },
    {
      id: 'Workshop',
      text: 'Workshop',
      icon: workicon2_active,
      activeIcon: workicon2_active,
      goToLink: '',
    },
    {
      id: 'Finished',
      text: 'Finished',
      icon: workicon2_active,
      activeIcon: workicon2_active,
      goToLink: '',
    },
  ];

  const [hasAssembling, setHasAssembling] = useState(false);
  const [hasWarrantyRegistration, setHasWarrantyRegistration] = useState(false);

  useEffect(() => {
    const fetchAssemblingAndWarrantyData = async () => {
      try {
        // Fetch assembling data
        const assemblingResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/api/assembling/product/${db_data?.id}/`
        );
        const assemblingData = await assemblingResponse.json();
        const hasAssemblingData = assemblingData.length > 0;
        setHasAssembling(hasAssemblingData);

        if (hasAssemblingData) {
          // Fetch warranty registration data if assembling data exists
          const warrantyResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/api/warranty-registration/${db_data?.id}/`
          );
          const warrantyData = await warrantyResponse.json();
          console.log('Warranty Data:', warrantyData);
          setHasWarrantyRegistration(warrantyData.length > 0);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error here
      }
    };

    if (db_data?.id) {
      fetchAssemblingAndWarrantyData();
    }
  }, [db_data?.id]);

  const validateForMaintenance = (link) => {
    if (
      db_data?.warranty_end_date !== undefined &&
      db_data?.warranty_end_date !== null
    ) {
      window.location.href = link;
      return;
    }
    if (!hasAssembling) {
      toast.error('Please fill assembling.');
    } else if (hasAssembling && !hasWarrantyRegistration) {
      toast.error('Fill the vehicle registration first.');
    } else if (hasAssembling && hasWarrantyRegistration) {
      window.location.href = link;
    }
  };

  const handleVehicleLinkClick2 = (link) => {
    if (props.onItemClick) {
      props.onItemClick();
    }
    if (link.startsWith('http')) {
      // If the link starts with "http" or "https", open it in a new window
      window.open(link, '_blank');
    } else {
      if (link === '/service/addNewService') {
        validateForMaintenance(link);
        return;
      }
      // Otherwise, navigate to the link within the same application
      window.location.href = link;
    }
  };

  // SET ACTIVE STATE ACCORDING TO URL
  useEffect(() => {
    const currentPath = location.pathname;
    setActiveLink(
      currentPath === '/dashboard'
        ? 'dashboard'
        : currentPath === '/settings'
        ? 'settings'
        : currentPath === '/vehicleData' || '/service/maintainence'
        ? 'vehicle'
        : ''
    );
    setActiveVehiclelink(
      currentPath === '/service/addNewService'
        ? 'maintainence'
        : currentPath === '/service/maintainence'
        ? 'reparing'
        : currentPath === '/service/parts'
        ? 'https://cfmoto.motohobi.ee/product/2022-motorcycle-cf800-5eu5'
        : currentPath === 'service/usermanual'
        ? 'usermanual'
        : currentPath === 'service/logout'
        ? 'logout'
        : currentPath === 'service/servicemanual'
        ? 'servicemanual'
        : currentPath === 'service/Waranty'
        ? 'warranty'
        : null
    );

    activeVehiclelink === 'warranty' ? console.log(true) : console.log(false);
  }, [location, activeVehiclelink]);
  const [activeLanguage, setActiveLanguage] = useState('EN'); // Set the initial active language
  // Define your getActiveIcon function and handleLanguageChange function

  // const languages = ['EN', 'ET', 'LV', 'FI'];

  const countries = [
    { value: 'EN', label: 'English', flag: us },
    { value: 'ET', label: 'Estonian', flag: et },
    { value: 'FN', label: 'Finnish', flag: fn },
    { value: 'LV', label: 'Latvian', flag: lv },
  ];

  // Initialize state with the value from localStorage if it exists, otherwise default to initial language from i18n
  const [selectedOption, setSelectedOption] = useState(
    JSON.parse(localStorage.getItem('selectedLanguage')) ||
      countries.find((c) => c.value === (i18n.language || 'EN'))
  );

  useEffect(() => {
    // Save to localStorage whenever the selectedOption changes
    localStorage.setItem('selectedLanguage', JSON.stringify(selectedOption));
    // Change the application language
    i18n.changeLanguage(selectedOption.value);
  }, [selectedOption]);

  const handleLanguageChange = (language) => {
    const selectedOption = countries.find((c) => c.value === language);
    setSelectedOption(selectedOption);
    setActiveLanguage(language);
  };

  // const getActiveIcon = (language) => {
  //   switch (language) {
  //     case 'EN':
  //       return us;
  //     case 'ET':
  //       return et;
  //     case 'LV':
  //       return lv;
  //     case 'FI':
  //       return fn;
  //     default:
  //       return us;
  //   }
  // };

  useEffect(() => {
    const timer = setTimeout(() => {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/addwork/add-work/check-status/${db_data?.vin_code}/`
        )
        .then((response) => {
          setWorkStatusData(response.data);
          if (typeof props.setCheckStatus === 'function') {
            props.setCheckStatus(response.data);
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }, 0);

    // Cleanup function to clear the timer if the component unmounts before the timeout is reached
    return () => clearTimeout(timer);
  }, [location, db_data?.vin_code]);

  return (
    <>
      <div
        className={`w-[100%] md:w-[16.25rem] xl:w-[21.125rem] bg-black-200 fixed top-0 left-0 bottom-0 flex flex-col justify-between overflow-y-auto sidebar z-10 ${
          props.openSidebar ? 'translate-x-0' : '-translate-x-full'
        } md:translate-x-0 transition`}
      >
        <button
          className='absolute top-4 right-4 z-10 px-3 py-2 md:hidden bg-gradient text-white rounded-full'
          onClick={() => props.setOpenSidebar(false)}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-6 w-6 text-white'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={2}
              d='M6 18L18 6M6 6l12 12'
            />
          </svg>
        </button>

        {/* TOP PORTION */}
        <div>
          {/* LOGO */}
          {/* <div className="pt-[73px]">
            <img className="mx-auto" src={logo} alt="logo" />
          </div> */}
          <div
            className='pt-[73px]'
            onClick={() => (window.location.href = '/dashboard')}
          >
            <img className='mx-auto' src={logo} alt='logo' />
          </div>

          <div>
            {/* Your other components */}

            {/* Mobile Language Selector */}
            <MobileLanguageSwitcher
              handleLanguageChange={handleLanguageChange}
              activeLanguage={selectedOption.value}
            />
          </div>

          {/* LINKS */}
          <ul className='pt-[15px] px-[18px] md:px-3 xl:px-[18px] space-y-5'>
            {linksTop.map((link) => (
              <li key={link.id}>
                <Link
                  to={link.goToLink}
                  className={`w-full flex items-center justify-start pl-11 md:pl-4 xl:pl-12 py-2.5 rounded-[10px] ${
                    activeLink === link.id
                      ? 'bg-gradient text-white'
                      : 'text-lightWhite'
                  }`}
                  href='#abc'
                  onClick={() => handleLinkClick(link.id)}
                >
                  <span className='w-[26px] mr-8 md:mr-3 xl:mr-[30px]'>
                    <img
                      src={activeLink === link.id ? link.activeIcon : link.icon}
                      alt='icon'
                    />
                  </span>
                  <span className='text-f_22_l_28'>{link.text}</span>
                </Link>
              </li>
            ))}
          </ul>

          {/* ID */}
          {localStorage.getItem('vinnData') && (
            <div
              style={{ cursor: 'pointer' }}
              className='mt-[30px] flex items-center justify-center'
              onClick={() => navigate('/vehicleData')}
            >
              <span className='text-f_24_l_30'>
                {JSON.parse(localStorage.getItem('vinnData'))?.data?.vin_code}
              </span>
            </div>
          )}

          {/* Vehicle Menu */}
          {activeLink === 'vehicle' && (
            <div className='text-f_24_l_30 flex flex-col mb-10'>
              {vehicleMenu.map((link, index) => (
                <NavLink
                  key={link.id}
                  onClick={() => {
                    if (index === vehicleMenu.length - 5) {
                      // setShowModal3(true);
                      fetchDataByLanguage('EN');
                      // fetchDataByLanguageusermanual('EN')fetchDataByLanguage('EN')
                    } else if (index === vehicleMenu.length - 1) {
                      setShowModal2(true);
                      props.setOpenSidebar(false);
                    } else if (index === vehicleMenu.length - 4) {
                      setshowModalusermanual(true);
                      props.setOpenSidebar(false);
                    } else if (link?.disabled) {
                      // No action
                    } else {
                      handleVehicleLinkClick2(link.goToLink);
                    }
                  }}
                  className={({ isActive }) => {
                    return `    mt-7 pt-[15px] px-[18px] xsm:px-14 sm:px-14 xl:pl-14 ${
                      link.disabled ? 'cursor-not-allowed' : ''
                    } text-${
                      location.pathname === link.goToLink ? 'white' : 'orange'
                    }`;
                  }}
                >
                  {link.text}
                </NavLink>
              ))}
            </div>
          )}
          {/* worknav */}
          {/* {db_data && db_data?.vin_code && (
            <ul className='mt-7 pt-[15px] md:px-[18px] xsm:px-14 sm:px-14 space-y-6'>
              <div className='text-f_24_l_30 flex flex-col mb-10'>
                <Link
                  rel='stylesheet'
                  href=' '
                  className='mt-6 xl:pl-8 md:pl-10 text'
                >
                  WORK
                </Link>
                {workMenu?.map((link, index) => (
                  <Link
                    key={link.id}
                    to={link.disabled ? undefined : link.goToLink}
                    onClick={() => {
                      console.log('location.pathname:', location.pathname);
                    }}
                    className={`mt-6 xl:pl-8 md:pl-10 flex items-center ${
                      link.disabled ? 'cursor-not-allowed' : ''
                    } ${
                      location.pathname === link.goToLink
                        ? 'text-white'
                        : 'text-orange'
                    }`}
                  >
                    <span className='w-6 mr-4 md:mr-3 xl:mr-6'>
                      <img
                        src={
                          activeLink === link.id ? link.activeIcon : link.icon
                        }
                        alt='icon'
                      />
                    </span>
                    <span className='text-f_22_l_28'>{link.text}</span>
                  </Link>
                ))}
              </div>
            </ul>
          )} */}
          {/* {activeLink === "vehicle" && (
 <ul className="pt-[15px] px-[18px] md:px-3 xl:px-[18px] space-y-5">
 <Link rel="stylesheet" href=" "  className="text-orange text-f_22_l_28 w-full flex items-center justify-start pl-11 md:pl-4 xl:pl-12 py-2.5 rounded-[10px]">{t("work")}</Link>
            {workMenu.map((link) => (
              <li key={link.id}>
                <Link
                  to={link.goToLink}
                  className={`w-full flex items-center justify-start pl-11 md:pl-4 xl:pl-12 py-2.5 rounded-[10px] ${
                    activeLink === link.id
                      ? "bg-gradient text-white"
                      : "text-lightWhite"
                  }`}
                  href="#abc"
                  onClick={() => handleLinkClick(link.id)}
                >
                  <span className="w-[26px] mr-8 md:mr-3 xl:mr-[30px]">
                    <img
                      src={activeLink === link.id ? link.activeIcon : link.icon}
                      alt="icon"
                    />
                  </span>
                  <span className="text-f_22_l_28 text-orange">{link.text}</span>
                </Link>
              </li>
            ))}
          </ul>
            )} */}

          <Modal
            isOpen={showModal3}
            onRequestClose={() => setShowModal3(false)}
            className='fixed  inset-0 flex items-center justify-center z-50'
            overlayClassName='fixed inset-0 bg-black bg-opacity-50'
          >
            <div className='bg-main rounded-lg p-6 w-96'>
              <h1 className='text-xl font-bold mb-4'>
                Select Language For Service Manual{' '}
              </h1>
              <div className='mb-6 grid grid-cols-1 md:grid-cols-2 gap-4'>
                <button
                  className='selectable-button border border-gray-300 rounded-lg p-2'
                  onClick={() => fetchDataByLanguage('EN')}
                  data-language='english'
                >
                  English
                </button>
                <button
                  className='selectable-button border border-gray-300 rounded-lg p-2'
                  onClick={() => fetchDataByLanguage('ET')}
                  data-language='estonian'
                >
                  Estonian
                </button>
                <button
                  className='selectable-button border border-gray-300 rounded-lg p-2'
                  onClick={() => fetchDataByLanguage('LV')}
                  data-language='Latvian'
                >
                  Latvian
                </button>
                <button
                  className='selectable-button border border-gray-300 rounded-lg p-2'
                  onClick={() => fetchDataByLanguage('FI')}
                  data-language='Finnish'
                >
                  Finnish
                </button>
              </div>

              <div className='flex justify-end mt-6'>
                {/* <button
                    className="px-4 py-2  text-white rounded hover:bg-blue-700 mr-2"
                    tyle={{ backgroundColor: "#009bb4" }}
                    onClick={()=>{
                      
                    
                      setShowModal(false);
                      
                    }}
                  > */}
                {/* Confirm
                  </button> */}
                <button
                  className='px-4 py-2 bg-white text-white rounded hover:bg-gray-500'
                  s
                  onClick={() => {
                    setShowModal3(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>

          <Modal
            isOpen={showModal2}
            onRequestClose={() => setShowModal2(false)}
            className='fixed  inset-0 flex items-center justify-center z-50'
            overlayClassName='fixed inset-0 bg-black bg-opacity-50'
          >
            <div className='bg-gradient rounded-lg p-6'>
              <h1 className='text-xl font-bold mb-4'>Confirmation</h1>
              <p className=' mb-6 text-black'>
                Are you sure you want to remove the vehicle?
              </p>

              <div className='flex justify-end mt-6'>
                <button
                  className='px-4 py-2  text-white rounded hover:bg-blue-700 mr-2'
                  tyle={{ backgroundColor: '#009bb4' }}
                  onClick={() => {
                    logout_function();
                    localStorage.removeItem('vinnData');
                    localStorage.removeItem('bulletinData');
                    localStorage.removeItem('servicesCheckList');
                    localStorage.removeItem('mileageEnteredForCheckList');
                    setShowModal(false);
                    navigate('/dashboard');
                  }}
                >
                  Confirm
                </button>
                <button
                  className='px-4 py-2 bg-white text-white rounded hover:bg-gray-500'
                  s
                  onClick={() => {
                    setShowModal2(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
          <Modal
            isOpen={showModalusermanual}
            onRequestClose={() => setshowModalusermanual(false)}
            className='fixed  inset-0 flex items-center justify-center z-50'
            overlayClassName='fixed inset-0 bg-black bg-opacity-50'
          >
            <div className='bg-main rounded-lg p-6 w-96'>
              <h1 className='text-xl font-bold mb-4'>
                Select Language For User Manual
              </h1>
              <div className='mb-6 grid grid-cols-1 md:grid-cols-2 gap-4'>
                <button
                  className='selectable-button border border-gray-300 rounded-lg p-2'
                  onClick={() => fetchDataByLanguageusermanual('EN')}
                  data-language='english'
                >
                  English
                </button>
                <button
                  className='selectable-button border border-gray-300 rounded-lg p-2'
                  onClick={() => fetchDataByLanguageusermanual('ET')}
                  data-language='estonian'
                >
                  Estonian
                </button>
                <button
                  className='selectable-button border border-gray-300 rounded-lg p-2'
                  onClick={() => fetchDataByLanguageusermanual('LV')}
                  data-language='Latvian'
                >
                  Latvian
                </button>
                <button
                  className='selectable-button border border-gray-300 rounded-lg p-2'
                  onClick={() => fetchDataByLanguageusermanual('FI')}
                  data-language='Finnish'
                >
                  Finnish
                </button>
              </div>

              <div className='flex justify-end mt-6'>
                {/* <button
                    className="px-4 py-2  text-white rounded hover:bg-blue-700 mr-2"
                    tyle={{ backgroundColor: "#009bb4" }}
                    onClick={()=>{
                      
                    
                      setShowModal(false);
                      
                    }}
                  > */}
                {/* Confirm
                  </button> */}
                <button
                  className='px-4 py-2 bg-white text-white rounded hover:bg-gray-500'
                  s
                  onClick={() => {
                    setshowModalusermanual(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>

          {/* <Modal
  isOpen={showModal}
  onRequestClose={() => setShowModal(false)}
  className="fixed inset-0 flex items-center justify-center z-50"
  overlayClassName="fixed inset-0 bg-black bg-opacity-50"
>
  <div className="bg-black-500 rounded-lg p-6">
    <h1 className="text-xl font-bold mb-4">Confirmation</h1>
    <p className="bg-gradient-text mb-6">Are you sure you want to remove the vehicle?</p>
    <div className="flex justify-end">
      <button
        className="px-4 py-2  text-white rounded hover:bg-blue-600 mr-2"
        onClick={() => {
          localStorage.removeItem("vinnData");
          setShowModal(false);
          navigate("/dashboard");
        }}
      >
        Confirm
      </button>
      <button
        className="px-4 py-2  text-white rounded hover:bg-gray-400"
        onClick={() => setShowModal(false)}
      >
        Cancel
      </button>
    </div>
  </div>
</Modal> */}
        </div>

        {/* BOTTOM PORTION */}
      </div>

      {/* MOBILE CLOSE SIDEBAR OVERLAY */}
      <div
        className={props.openSidebar ? 'md:hidden fixed inset-0' : 'md:hidden'}
        onClick={() => props.setOpenSidebar(false)}
      ></div>
    </>
  );
}

export default Sidebar;
