import React from 'react';
import Select from 'react-tailwindcss-select';

const MultiSelectDropdown = ({
  options,
  onChange,
  value,
  name,
  placeholder,
  allowTextWrap = false,
  customStyles = {},
  isMulti = true,
  isDisabled = false,
}) => {
  return (
    <Select
      isMulti={isMulti}
      name={name}
      options={options}
      value={value}
      onChange={onChange}
      isDisabled={isDisabled}
      placeholder={placeholder}
      classNames={{
        menuButton: ({ isDisabled }) =>
          `flex w-full min-h-[45px] text-f_18_l_23 text-white border-[0.5px] border-white-100 rounded-[10px] transition-all duration-300 focus:ring-0 focus:outline-none overflow-hidden ${
            isDisabled
              ? 'bg-gray-200'
              : 'bg-white hover:border-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500/20'
          } ${customStyles.menuButton || ''}`,
        menu: `absolute z-10 w-full bg-black-500 shadow-lg border rounded-[10px] py-1 mt-1.5 text-f_18_l_23 ${
          customStyles.menu || ''
        }`,
        listItem: ({ isSelected }) =>
          `block transition duration-200 px-4 py-3 cursor-pointer select-none rounded whitespace-normal break-words ${
            isSelected
              ? `text-white-100 bg-blue-500`
              : `text-white-100 hover:bg-blue-100 hover:text-blue-500`
          } ${customStyles.listItem || ''}`,
        tagItem: () =>
          `bg-black-600 text-white-100 text-[12px] border rounded-sm flex items-center space-x-1 p-1 m-0.5 max-w-full`,
        tagItemText: () =>
          `block whitespace-normal break-words overflow-hidden text-ellipsis`,
        valueContainer: () =>
          `flex flex-wrap gap-1 p-2 overflow-x-hidden overflow-y-auto max-h-20`,
        singleValue: () =>
          `whitespace-normal break-words overflow-hidden text-ellipsis`,
        multiValue: () => `flex flex-wrap gap-1 max-w-full overflow-hidden`,
        input: () => `whitespace-normal break-words`,
      }}
      primaryColor='blue'
      isClearable={true}
      formatOptionLabel={(option) => (
        <div className='whitespace-normal break-words py-1'>{option.label}</div>
      )}
    />
  );
};

export default MultiSelectDropdown;
