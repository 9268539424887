import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
const savedLanguage = localStorage.getItem('selectedLanguage');
const defaultLanguage = savedLanguage ? JSON.parse(savedLanguage).value : 'EN';
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      EN: {
        translation: {
          //dasboard
          dasboard_learn: 'Learn How to use this System',
          DESC_USE:
            'Here you will find simple tutorials on how to use the CFMOTO WORLD software. A new user needs to read carefully and understand how the system works. We have created guides for the dealer, the service person and the vehicle owner.',
          Step_1: 'Step 1',
          Step_1_desc:
            'Everyone who joins the system identifies themselves and the system identifies a specific person. Each person is personally responsible for the requests made with their user account and the data entered.',
          Step_2: 'Step 2',
          Step_2_desc:
            'Each user group can perform queries, enter data and view the previous history of the vehicle according to the permitted rights.',
          Step_3: 'Step 3',
          Step_3_desc:
            'Dear partners, all the information entered here is necessary for you and other partners. To simplify vehicle maintenance and to know how to perform the best maintenance for this particular vehicle.',
          //sidebar
          Dashboard: 'Dashboard',
          Warranty_end_date: 'Warranty end date',
          Plate_number: 'Plate number',
          REGISTRATION_YEAR: 'REGISTRATION YEAR',
          Manufacturer: 'Manufacturer',
          Engine_Name: 'Engine name ',
          Engine_Number: 'Engine number',
          work: 'work',
          works: 'works',
          add: 'Add',
          MY: 'My',
          pending: 'Pending',
          finished: 'Finished',

          Vehicle: 'Vehicle',
          Maintenance: 'Maintenance',
          Repairing: 'Repairing',
          Parts: 'Parts',
          Accessories: 'Accessories',
          Service_manual: 'Service manual',
          User_manual: 'User manual',
          Warranty: 'WARRANTY',
          Warrantynew: 'Warranty',
          Bulletins: 'Bulletins',
          Log_out: 'Vehicle Log Out',
          //vehicle data
          Warranty_Registration: 'Warranty Registration',
          History: 'HISTORY ',
          SKU: 'SKU',
          VIN_CODE: 'VIN CODE',
          COUNTRY: 'COUNTRY',
          SERIES: 'SERIES',
          MODEL_NAME: 'MODEL NAME',
          FACTORY_NAME: 'FACTORY NAME',
          COLOR: 'COLOR',
          EU_TYPE_APPROVAL: 'EU TYPE APPROVAL',
          STEERING_POWER: 'STEERING POWER',
          WHEELS: 'WHEELS',
          SCREEN: 'SCREEN',
          LIGHTS: 'LIGHTS',
          CARGO_COMPARTMENTS: 'CARGO COMPARTMENTS',
          COMMUNICATION_TERMINAL: 'COMMUNICATION TERMINAL',
          OWNER_NAME: 'OWNER NAME',
          OWNER_PHONE: 'OWNER PHONE',
          OWNER_EMAIL: 'OWNER EMAIL',
          VEHICLE_NUMBER_PLATE: 'VEHICLE NUMBER PLATE',
          IMPORTER: 'IMPORTER',
          DEALER: 'DEALER',
          //maintainence
          Select_the_mileage: 'Select the mileage',
          next: 'Next',
          Mileage_at_maintenance: 'Mileage at maintenance',
          Maintenance_description: 'Maintenance description',
          Spare_parts_for_service: 'Spare parts for service:',
          Vehicle_photos: 'Vehicle photos:',
          Vehicle_photos_desc:
            'Photo of the speedometer (numbers must be legible), VIN code (numbers must be legible), 45° front view ja 45° rear view',
          Maintainence_list_desc: 'Maintenance service checklist',
          Maintainence_list_desc_2: 'Please mark when you finish each section',
          Mechanic_comment: 'Mechanic comment :',
          Maintainence_button_text: 'Save maintenance  Data',
          //waranty
          waranty_milage: 'Mileage at service ',
          Failure_description: 'Failure description',
          Upload_photos_and_videos: 'Upload photos and videos',
          Replaced_parts: 'Replaced parts',
          Cause: 'Cause',
          repair: 'Repair',
          Remarks: 'Remarks',
          Review: 'Review',
          Save_warranty_data: 'Save warranty data',
          Update_warranty_data: 'Update warranty data',
          //repairing
          Mileage_of_Repairing: 'Mileage of Repairing',
          Customer_description_of_errors: 'Vehicle Owner Description',
          The_receiver_oftheworks_description: 'Repairing Description',
          Feedback_from_the_mechanic: 'Feedback from the mechanic',
          Save_repairing_Data: 'Save repairing Data',
          Update_repairing_data: 'Update Repairing Data',
          //bulletin
          Bulletins_description: 'Bulletins description:',
          Factory_instructions: 'Factory instructions',
          OPEN_FILE: 'OPEN FILE',
          bulettin_Mechanic_comment: 'Mechanic comment :',
          Bulletin_executed: 'Bulletin executed',
          //waranty registration
          Company: 'COMPANY',
          registration_desc:
            'The vehicle has been handed over to the owner. All the necessary briefing has been given to the owner.',
          // vinn number page
          terVINnumber: 'Enter VIN/Plate number',
          vinnumber: 'VIN number',
          platenumber: 'Plate number',
          SUBMIT: 'SUBMIT',
          OR: 'or',
          Addspeedometer: 'Add speedometer',
          addvincode: 'Add  VIN code',
          add45front: 'Add 45° front view',
          add45back: 'Add 45° rear view',
          addleft: 'Add left view',
          addright: 'Add right view',
          instruction: 'Name of the Person who was Instructed',
          company_instruction: 'Only if the vehicle is sold to a Company',
          //Assembling
          check_the_vin_code: 'Check the vin code',
          and_make_photo: 'and make photo',
          assembly_confirmation: 'I confirm that the assembly was carried out according to the instructions.',
          mechanic_comment: 'Mechanic comment',
          issues_found: 'issues found',
          completed_assembly: 'Completed assembly',
          issues_found_during_the_assembly_process:
            'Issues found during the assembly process.',
          open_warranty: 'Open warranty',
          add_photo: 'Add Photo',
          add_video:'Add Video',

          assembling: 'ASSEMBLING',

          //Add work
          ADD_WORK: 'Add Work',
          VEHICLE_OWNER_DESCRIPTION: 'Vehicle Owner Description',
          WRITE_VEHICLE_OWNER_DESCRIPTION: 'Write vehicle owner description',
          CURRENT_MILEAGE: 'The current mileage is approx',
          KILOMETERS: 'km',
          MAINTENANCE: 'Maintenance',
          LAST_SERVICE_MESSAGE:
            'The Last service was performed on {{date}}, and the mileage was {{mileage}} km',
          NO_SERVICE_HISTORY:
            'This vehicle has no previous service history in the CFMOTO World system.',
          REPAIRING: 'Repairing',
          REPAIRING_DESCRIPTION: 'Repairing Description',
          DESCRIPTION_TO_MECHANIC: 'Description to mechanic',
          WARRANTY: 'Warranty',
          WARRANTY_DESCRIPTION: 'Warranty description',

          WORK_ORDERER_NAME: 'Work Orderer Name',
          ENTER_WORK_ORDERER_NAME: "Enter work orderer's name",
          WORK_ORDERER_EMAIL: 'Work Orderer Email',
          ENTER_WORK_ORDERER_EMAIL: "Enter work orderer's email",
          WORK_ORDERER_PHONE: 'Work Orderer Phone Number',
          ENTER_WORK_ORDERER_PHONE: "Enter work orderer's phone number",
          CREATED_BY:
            'created by {{user}} at {{date}} from company {{company}}',
          add_work_to_queue: 'Add to work queue',
          Continue: "Open checklist",
          Repairing: 'Repairing'
        },
      },
      ET: {
        translation: {
          //dasboard
          dasboard_learn: 'Õpi süsteemi kasutama',
          DESC_USE:
            'Siit leiad CFMOTO WORLD programmi kasutusjuhendid. Uus kasutaja peaks juhendid hoolikalt läbi lugema ning endale süsteemi selgeks tegema. Kasutusjuhendid oleme loonud edasimüüjale, hooldajale ning sõiduki omanikule.',
          Step_1: 'Samm 1',
          Step_1_desc:
            'Süsteemiga ühinedes, kasutaja tuvastab enda isiku. Iga isik on vastutav enda kasutaja alt sisestatud info ja andmete korrektsuses.',
          Step_2: 'Samm 2',
          Step_2_desc:
            'Iga kasutajagrupp saab oma kasutaja õigustele vastavalt teha päringuid, sisestada andmeid ning näha ajalugu.',
          Step_3: 'Samm 3',
          Step_3_desc:
            'Austatud partnerid, kogu informatsioon on teile ning teistele partneritele vajalik hoolduse süsteemi lihtsustamiseks ning parima hoolduse teostamiseks kindlale sõidukile.',
          //sidebar
          Dashboard: 'Juhtpaneel',
          Manufacturer: 'tootja',
          Plate_number: 'REG.NUMBER',
          Engine_Name: 'MOOTORI TÄHIS',
          REGISTRATION_YEAR: 'REGISTREERIMIS AASTA',
          Engine_Number: 'MOOTORI NUMBER',
          work: 'töökäsk',
          works: 'töökäsk',
          Warranty_end_date: 'GARANTII LÕPP KUUPÄEV',
          add: 'Lisa',
          MY: 'Minu',
          pending: 'Ootel',
          finished: 'Lõpetatud',
          Vehicle: 'Sõiduk',
          Maintenance: 'Hooldus',
          Repairing: 'Remont',
          Parts: 'Varuosad',
          Accessories: 'Lisavarustus',
          Service_manual: 'Hoolduse juhend',
          User_manual: 'Kasutusjuhend',
          Warranty: 'Garantii',
          Warrantynew: 'Garantii',
          Bulletins: 'Teated',
          Log_out: 'Logi välja sõidukist',
          add_photo: 'Lisa foto',
          add_video:'Lisa video',
          //vehicle data
          Warranty_Registration: 'Garantii registreerimine',
          History: 'AJALUGU',
          SKU: 'SKU',
          VIN_CODE: 'VIN KOOD',
          COUNTRY: 'RIIK',
          SERIES: 'SEERIA',
          MODEL_NAME: 'MUDEL',
          FACTORY_NAME: 'TEHASETÄHIS',
          COLOR: 'VÄRVUS',
          EU_TYPE_APPROVAL: 'EU TÜÜBIKINNITUS',
          STEERING_POWER: 'ROOLIVÕIMENDI',
          WHEELS: 'REHVID',
          SCREEN: 'EKRAAN',
          LIGHTS: 'TULED',
          CARGO_COMPARTMENTS: 'PANIPAIGAD',
          COMMUNICATION_TERMINAL: 'T-BOX',
          OWNER_NAME: 'OMANIKU NIMI',
          OWNER_PHONE: 'OMANIKU TELEFON',
          OWNER_EMAIL: 'OMANIKU E-MAIL',
          IMPORTER: 'MAALETOOJA',
          DEALER: 'MÜÜJA',
          //maintainence
          Select_the_mileage: 'Valige läbisõit',
          next: 'järgmiseks',
          Mileage_at_maintenance: 'Hoolduse läbisõit',
          Maintenance_description: 'Hoolduse kirjeldus',
          Spare_parts_for_service: 'Hoolduse varuosad:',
          Vehicle_photos: 'Sõiduki pildid:',
          Vehicle_photos_desc:
            'Pilt spidomeetrist ( peab olema loetav ), VIN kood ( peab olema loetav ), 45° eestvaade ja 45° tagantvaade',
          Maintainence_list_desc: 'Hoolduse kontrollnimekiri',
          Maintainence_list_desc_2: 'Palun märgi peale iga sammu lõpetamist',
          Mechanic_comment: 'Mehaaniku kommentaar:',
          Maintainence_button_text: 'Salvesta hoolduse andmed',
          //waranty
          waranty_milage: 'Sõiduki läbisõit ',
          Failure_description: 'Vea kirjeldus',
          Replaced_parts: 'Vahetatud varuosad',
          Upload_photos_and_videos: 'Piltide ja videote üles laadimine',
          Cause: 'Põhjuse kirjeldus',
          repair: 'Remont',
          Remarks: 'Märkused',
          Review: 'Ülevaade',
          Save_warranty_data: 'Salvesta garantii andmed',
          //repairing
          Mileage_of_Repairing: 'Läbisõit',
          Customer_description_of_errors: 'Sõiduki omaniku kirjeldus',
          The_receiver_oftheworks_description: 'Remondi kirjeldus',
          Feedback_from_the_mechanic: 'Mehaaniku tagasiside',
          Save_repairing_Data: 'Salvesta remonttöö andmed',
          //bulletin
          Bulletins_description: 'Teadete kirjeldus:',
          Factory_instructions: 'Tehase juhised',
          OPEN_FILE: 'AVA FAIL',
          bulettin_Mechanic_comment: 'Mehaaniku kommentaar:',
          Bulletin_executed: 'Bulletin executed',
          //waranty registration
          Company: 'ETTEVÕTE',
          registration_desc:
            'Sõiduk on omanikule üle antud. Omanikule on antud kogu vajalik instruktaaž.',
          // vinn number page
          EnterVINnumber: 'Sisestage VIN-kood/numbrimärk',
          vinnumber: 'VIN number',
          platenumber: 'Numbrimärk',
          SUBMIT: 'SISENE SÕIDUKISSE',
          OR: 'või',
          Addspeedometer: 'Lisa spidomeeter',
          addvincode: 'Lisa VIN-kood',
          add45front: 'Lisage 45° eestvaade',
          add45back: 'Lisage 45° tagantvaade',
          addleft: 'Lisa vasak vaade',
          addright: 'Lisa parem vaade',
          instruction:
            'Inimese nimi kellele on tehtud sõiduki kohta instruktaaž',
          company_instruction: 'Täita ainult siis kui müüdud ettevõttele',
          VEHICLE_NUMBER_PLATE: 'REGISTREERIMIS NUMBER',
          //Assembling
          check_the_vin_code: 'Kontrollige vin-koodi',
          and_make_photo: 'ja tee foto',
          assembly_confirmation:
            'Kinnitan, et kokkupanek viidi läbi vastavalt juhistele.',
          mechanic_comment: 'Mehaaniku kommentaar',
          issues_found: 'Ava garantii juhtum',
          completed_assembly: 'Valmis kokkupanek',
          issues_found_during_the_assembly_process:
            'Koostamise käigus leitud probleemid.',
          open_warranty: 'Avatud garantii',
          add_photo: 'Lisa foto',
          add_video:'Lisa Video',
          assembling: 'SÕIDUKI KOMPLEKTEERIMINE',

          //Add work
          ADD_WORK: 'Lisa Töö',
          VEHICLE_OWNER_DESCRIPTION: 'Sõiduki omaniku kirjeldus',
          WRITE_VEHICLE_OWNER_DESCRIPTION: 'Kirjuta sõiduki omaniku kirjeldus',
          CURRENT_MILEAGE: 'Praegune läbisõit on umbes',
          KILOMETERS: 'km',
          MAINTENANCE: 'Hooldus',
          LAST_SERVICE_MESSAGE:
            'Viimane hooldus teostati {{date}} ja läbisõit oli {{mileage}} km',
          NO_SERVICE_HISTORY:
            'Sellel sõidukil puudub varasem hooldusajalugu CFMOTO World süsteemis.',
          REPAIRING: 'Remont',
          REPAIRING_DESCRIPTION: 'Remonttöö kirjeldus',
          DESCRIPTION_TO_MECHANIC: 'Remonttöö selgitus mehaanikule',
          WARRANTY: 'Garantii',
          WARRANTY_DESCRIPTION: 'Garantii juhtumi kirjeldus',

          WORK_ORDERER_NAME: 'Töö tellija nimi',
          ENTER_WORK_ORDERER_NAME: 'Sisesta töö tellija nimi',
          WORK_ORDERER_EMAIL: 'Töö tellija e-post',
          ENTER_WORK_ORDERER_EMAIL: 'Sisesta töö tellija e-post',
          WORK_ORDERER_PHONE: 'Töö tellija telefoni number',
          ENTER_WORK_ORDERER_PHONE: 'Sisesta töö tellija telefoni number',
          CREATED_BY:
            'loodud kasutaja {{user}} poolt {{date}} ettevõttest {{company}}',
          add_work_to_queue: 'Lisa tööjärjekorda',
          Continue: 'Ava hoolduse nimekiri',
          Repairing: 'Remont'

        },
      },
      FN: {
        translation: {
          //dasboard
          dasboard_learn: 'Opi käyttämään järjestelmää',
          DESC_USE:
            'Tästä löydät CFMOTO WORLD -ohjelmiston käyttöohjeet. Uuden käyttäjän tulee lukea ohjeet huolellisesti ymmärtääkseen, kuinka järjestelmä toimii. Olemme laatineet ohjeet kauppaille, huoltohenkilöstölle sekä ajoneuvon omistajalle.',
          Step_1: 'Vaihe 1',
          Step_1_desc:
            'Kaikkien järjestelmään liittyvien tulee tunnistautua, järjestelmä tunnistaa kunkin henkilön. Kukin henkilö on itse vastuussa käyttäjätilillään tehdyistä pyynnöistä ja syötetyistä tiedoista.',
          Step_2: 'Vaihe 2',
          Step_2_desc:
            'Kukin käyttäjäryhmä voi tehdä kyselyjä, syöttää tietoja sekä tarkastella ajoneuvon historiaa käyttöoikeuksien mukaisesti.',
          Step_3: 'Vaihe 3',
          Step_3_desc:
            'Arvoisat yhteistyökumppanit, tähän annetut tiedot ovat tärkeitä sekä sinulle että toisille yhteistyökumppaneille. Ajoneuvon huollon helpottamiseksi ja juuri tälle ajoneuvolle parhaiden mahdollisten huoltotoimien tietämiseksi.',

          //sidebar
          Dashboard: 'Ohjauspaneeli',
          work: 'työtä',
          works: 'toimii',
          add: 'Lisää',
          MY: 'Minun',
          pending: 'Odottaa',
          finished: 'Valmis',
          Vehicle: 'Ajoneuvo',
          Maintenance: 'Huolto',
          Repairing: 'Korjaus',
          Parts: 'Osat',
          Accessories: 'Lisävarusteet',
          Service_manual: 'Huolto-ohjekirja',
          User_manual: 'Omistajan käsikirja',
          Warranty: 'Takuu',
          Warrantynew: 'Takuu',
          Bulletins: 'Tiedotteet',
          Log_out: 'Ajoneuvo Kirjauduulos',
          //vehicle data
          Warranty_Registration: 'Takuun rekisteröinti',
          History: 'HISTORIA',
          SKU: 'SKU',
          VIN_CODE: 'VIN-KOODI',
          COUNTRY: 'MAA',
          SERIES: 'SARJA',
          MODEL_NAME: 'MALLI',
          FACTORY_NAME: 'TEHDASNIMITYS',
          COLOR: 'VÄRI',
          EU_TYPE_APPROVAL: 'EU-TYYPPIHYVÄKSYNTÄ',
          STEERING_POWER: 'OHJAUSVOIMA',
          WHEELS: 'PYÖRÄT',
          SCREEN: 'NÄYTTÖ',
          LIGHTS: 'VALOT',
          CARGO_COMPARTMENTS: 'TAVARATILAT',
          COMMUNICATION_TERMINAL: 'VIESTINTÄPÄÄTE',
          OWNER_NAME: 'OMISTAJAN NIMI',
          OWNER_PHONE: 'OMISTAJAN PUHELINNUMERO',
          OWNER_EMAIL: 'OMISTAJAN SÄHKÖPOSTI',
          IMPORTER: 'MAAHANTUOJA',
          DEALER: 'KAUPPIAS',
          //maintainence
          Select_the_mileage: 'Valitse mittarilukema',
          next: 'Seuraava',
          Mileage_at_maintenance: 'Mittarilukema huollossa ',
          Maintenance_description: 'Huollon kuvaus',
          Spare_parts_for_service: 'Huollossa käytetyt varaosat:',
          Vehicle_photos: 'Ajoneuvon kuvat:',
          Vehicle_photos_desc:
            'Kuva nopeusmittarista (numeroiden tulee näkyä), VIN-koodista (numeroiden tulee näkyä), edestä 45° kulmassa ja takaa 45° kulmasta',
          Maintainence_list_desc: 'Huoltopalvelun tarkistuslista',
          Maintainence_list_desc_2:
            'Merkitse kukin kohde suorittamisen jälkeen',
          Mechanic_comment: 'Mekaanikon kommentti:',
          Maintainence_button_text: 'Saglabājiet apkopes datus',
          //waranty
          waranty_milage: 'Mittarilukema huollossa ',
          Failure_description: 'Vian kuvaus',
          Replaced_parts: 'Vaihdetut osat',
          Upload_photos_and_videos: 'Lataa kuvia ja videoita',
          Cause: 'Syy',
          repair: 'Korjaus',
          Remarks: 'Huomautukset',
          Review: 'Arvio',
          Save_warranty_data: 'Tallenna takuutiedot',
          //repairing
          Mileage_of_Repairing: 'Korjauksen kilometrimäärä',
          Customer_description_of_errors: 'Ajoneuvon omistajan kuvaus',
          The_receiver_oftheworks_description: 'Kuvaus korjauksesta',
          Feedback_from_the_mechanic: 'Mekaanikon kommentti',
          Save_repairing_Data: 'Tallenna korjaustiedot',
          //bulletin
          Bulletins_description: 'Ilmoituksen kuvaus:',
          Factory_instructions: 'Tehtaan ohjeet',
          OPEN_FILE: 'AVAA TIEDOSTO',
          bulettin_Mechanic_comment: 'Mekaanikon kommentti:',
          Bulletin_executed: 'Ilmoituksen vaatimat toimet suoritettu',
          //waranty registration
          Company: 'Yhtiö',
          registration_desc:
            'Auto on luovutettu omistajalle. Kaikki tarvittavat tiedotukset on annettu omistajalle.',
          // vinn number page
          EnterVINnumber: 'Syötä VIN-numero/Kilven-numero',
          vinnumber: 'VIN-numero',
          platenumber: 'Kilven-numero',
          SUBMIT: 'LÄHETÄ',
          OR: 'tai',
          Addspeedometer: 'Lisää nopeusmittari',
          addvincode: 'Lisää VIN-koodi',
          add45front: 'Lisää 45° etunäkymä',
          add45back: 'Lisää 45° näkymä takaa',
          addleft: 'Lisää vasen näkymä',
          addright: 'Lisää oikea näkymä',
          instruction: 'Sen henkilön nimi, jolle opastettiin',
          company_instruction: 'Täita ainult siis kui müüdud ettevõttele',

          //Assembling
          check_the_vin_code: 'Check the vin code',
          and_make_photo: 'and make photo',
          assembly_confirmation:
            'I confirm that the assembly was carried out according to the instructions.',
          mechanic_comment: 'Mechanic comment',
          issues_found: 'issues found',
          completed_assembly: 'Completed assembly',
          issues_found_during_the_assembly_process:
            'Issues found during the assembly process.',
          open_warranty: 'Open warranty',
          add_photo: 'Lisää valokuva',
          add_video:'Lisää Video',
          assembling: 'ASSEMBLING',
          //Add Work

          ADD_WORK: 'Lisää Töitä',
          VEHICLE_OWNER_DESCRIPTION: 'Ajoneuvon omistajan kuvaus',
          WRITE_VEHICLE_OWNER_DESCRIPTION:
            'Kirjoita ajoneuvon omistajan kuvaus',
          CURRENT_MILEAGE: 'Nykyinen mittarilukema on noin',
          KILOMETERS: 'km',
          MAINTENANCE: 'Huolto',
          LAST_SERVICE_MESSAGE:
            'Viimeinen huolto suoritettiin {{date}} ja mittarilukema oli {{mileage}} km',
          NO_SERVICE_HISTORY:
            'Tällä ajoneuvolla ei ole huoltohistoriaa CFMOTO World -järjestelmässä.',

          REPAIRING: 'Korjaus',
          REPAIRING_DESCRIPTION: 'Korjauksen kuvaus',
          DESCRIPTION_TO_MECHANIC: 'Kuvaus mekaanikolle',
          WARRANTY: 'TAKUU',
          WARRANTY_DESCRIPTION: 'Takuun kuvaus',

          WORK_ORDERER_NAME: 'Työn tilaajan nimi',
          ENTER_WORK_ORDERER_NAME: 'Syötä työn tilaajan nimi',
          WORK_ORDERER_EMAIL: 'Työn tilaajan sähköposti',
          ENTER_WORK_ORDERER_EMAIL: 'Syötä työn tilaajan sähköposti',
          WORK_ORDERER_PHONE: 'Töö tellija telefon',
          ENTER_WORK_ORDERER_PHONE: 'Syötä työn tilaajan puhelinnumero',
          CREATED_BY: 'luonut {{user}} {{date}} yrityksestä {{company}}',
          add_work_to_queue: 'Lisää työjonoon',
          Continue: 'Avaa huoltolista',
          Repairing: 'Korjaus'

        },
      },
      LV: {
        translation: {
          //dasboard
          dasboard_learn: 'Uzziniet, kā izmantot šo sistēmu',
          DESC_USE:
            'Šeit jūs atradīsiet vienkāršas pamācības par CFMOTO WORLD programmatūras lietošanu. Jaunam lietotājam ir rūpīgi jāizlasa un jāsaprot, kā sistēma darbojas. Mēs esam izveidojuši ceļvežus izplatītājam, apkalpojošajam personālam un transportlīdzekļa īpašniekam.',
          Step_1: '1. darbība',
          Step_1_desc:
            'Katrs, kas pievienojas sistēmai, identificē sevi un sistēma identificē konkrētu personu. Katra persona ir personīgi atbildīga par pieprasījumiem, kas veikti, izmantojot savu lietotāja kontu, un ievadītajiem datiem.',
          Step_2: '2. darbība',
          Step_2_desc:
            'Katra lietotāju grupa atbilstoši atļautajām tiesībām var veikt vaicājumus, ievadīt datus un apskatīt transportlīdzekļa iepriekšējo vēsturi.',
          Step_3: '3. darbība',
          Step_3_desc:
            'Cienījamie partneri, visa šeit ievadītā informācija ir nepieciešama jums un citiem partneriem. Lai vienkāršotu transportlīdzekļa apkopi un zinātu, kā vislabāk veikt apkopi šim konkrētajam transportlīdzeklim.',
          //sidebar
          Dashboard: 'Mērinstrumentu panelis',
          works: 'darbojas',
          work: 'strādāt',
          add: 'Pievienot',
          MY: 'Mana',
          pending: 'Gaida',
          finished: 'Pabeigts',
          Vehicle: 'Transportlīdzeklis',
          Maintenance: 'Apkope',
          Repairing: 'Remonts',
          Parts: 'Detaļas',
          Accessories: 'Piederumi',
          Service_manual: 'Servisa rokasgrāmata',
          User_manual: 'Lietotāja rokasgrāmata',
          Warranty: 'Garantija',
          Warrantynew: 'Garantija',
          Bulletins: 'Biļetens',
          Log_out: 'Transportlīdzeklis Izlogoties',
          //vehicle data
          Warranty_Registration: 'Garantijas reģistrācija',
          History: 'Vēsture',
          SKU: 'SKU',
          VIN_CODE: 'VIN KODS',
          COUNTRY: 'VALSTS',
          SERIES: 'SĒRIJA',
          MODEL_NAME: 'MODEĻA NOSAUKUMS',
          FACTORY_NAME: 'RŪPNĪCAS NOSAUKUMS',
          COLOR: 'KRĀSA',
          EU_TYPE_APPROVAL: 'EU TIPA APSTIPRINĀJUMS',
          STEERING_POWER: 'STURES Pastiprinātājs',
          WHEELS: 'RITEŅI',
          SCREEN: 'EKRĀNS',
          LIGHTS: 'GAISMAS',
          CARGO_COMPARTMENTS: 'Bagāžas nodalijums',
          COMMUNICATION_TERMINAL: 'KOMUNIKĀCIJAS TERMINĀLS',
          OWNER_NAME: 'ĪPAŠNIEKA VĀRDS',
          OWNER_PHONE: 'ĪPAŠNIEKA TĀLRUNIS',
          OWNER_EMAIL: 'ĪPAŠNIEKA E-PASTs',
          IMPORTER: 'IMPORTĒTĀJS',
          DEALER: 'Izplatītājs',
          //maintainence
          Select_the_mileage: 'Izvēlieties nobraukumu',
          next: 'Nākamais',
          Mileage_at_maintenance: 'Nobraukums apkopes laikā',
          Maintenance_description: 'Tehniskās apkopes apraksts',
          Spare_parts_for_service: 'Rezerves daļas servisam:',
          Vehicle_photos: 'Transportlīdzekļa fotogrāfijas:',
          Vehicle_photos_desc:
            'Spidometra fotoattēls (skaitļiem jābūt salasāmiem), VIN kodam (skaitļiem jābūt salasāmiem), 45° skats no priekšpuses un 45° skats no aizmugures',
          Maintainence_list_desc: 'Hooldusteenuste kontrollnimekiri',
          Maintainence_list_desc_2:
            'Lūdzu, atzīmējiet, kad esat pabeidzis katru sadaļu',
          Mechanic_comment: 'Mehāniķa komentārs:',
          Maintainence_button_text: 'Saglabājiet apkopes datus',
          //waranty
          waranty_milage: 'Nobraukums servisā',
          Failure_description: 'Kļūdu apraksts',
          Replaced_parts: 'Nomainītas detaļas',
          Upload_photos_and_videos:
            'Augšupielādējiet fotoattēlus un videoklipus',
          Cause: 'Cēlonis',
          repair: 'Remonts',
          Remarks: 'Piezīmes',
          Review: 'Pārskats',
          Save_warranty_data: 'Saglabājiet garantijas datus',
          //repairing
          Mileage_of_Repairing: 'Remonta nobraukums',
          Customer_description_of_errors:
            'Transportlīdzekļa īpašnieka apraksts',
          The_receiver_oftheworks_description: 'Remonta apraksts',
          Feedback_from_the_mechanic: 'Atsauksmes no mehāniķa',
          Save_repairing_Data: 'Saglabājiet remonta datus',
          //bulletin
          Bulletins_description: 'Biļetena apraksts:',
          Factory_instructions: 'Rūpnīcas instrukcijas',
          OPEN_FILE: 'ATVERT FAILU',
          bulettin_Mechanic_comment: 'Mehāniķa komentārs:',
          Bulletin_executed: 'Biļetens izpildīts',
          //waranty registration
          Company: 'Uzņēmums',
          registration_desc:
            'Automašīna ir nodota īpašniekam. Īpašniekam ir sniegta visa nepieciešamā instruktāža.',
          // vinn number page
          EnterVINnumber: 'Ievadiet VIN/Plāksnes numuru',
          vinnumber: 'VIN numurs',
          platenumber: 'Plāksnes numurs',
          SUBMIT: 'IESNIEGT',
          OR: 'vai',
          Addspeedometer: 'Pievienojiet spidometru',
          addvincode: 'Pievienojiet VIN kodu',
          add45front: 'Pievienojiet 45° priekšējo skatu',
          add45back: 'Pievienojiet 45° aizmugures skatu',
          addleft: 'Pievienot kreiso skatu',
          addright: 'Pievienojiet labo skatu',
          instruction: 'Tās personas vārds, kura saņēma norādījumus',
          //Assembling
          company_instruction: 'Only if the vehicle is sold to a Company',
          check_the_vin_code: 'Check the vin code',
          and_make_photo: 'and make photo',
          assembly_confirmation:
            'I confirm that the assembly was carried out according to the instructions.',
          mechanic_comment: 'Mechanic comment',
          issues_found: 'issues found',
          completed_assembly: 'Completed assembly',
          issues_found_during_the_assembly_process:
            'Issues found during the assembly process.',
          open_warranty: 'Open warranty',
          add_photo: 'Pievienot fotoattēlu',
          add_video:'Pievienot video',
          assembling: 'ASSEMBLING',

          // Add work
          company_instruction: 'Täita ainult siis kui müüdud ettevõttele',
          ADD_WORK: 'pievienot darbu',
          VEHICLE_OWNER_DESCRIPTION: 'Transportlīdzekļa īpašnieka apraksts',
          WRITE_VEHICLE_OWNER_DESCRIPTION:
            'Ierakstiet transportlīdzekļa īpašnieka aprakstu',
          CURRENT_MILEAGE: 'Pašreizējais nobraukums ir aptuveni',
          KILOMETERS: 'km',
          MAINTENANCE: 'Apkope',
          LAST_SERVICE_MESSAGE:
            'Pēdējā apkope tika veikta {{date}} un nobraukums bija {{mileage}} km',
          NO_SERVICE_HISTORY:
            'Šim transportlīdzeklim nav iepriekšējas apkopes vēstures CFMOTO World sistēmā.',
          REPAIRING: 'Remonts',
          REPAIRING_DESCRIPTION: 'Remonta apraksts',
          DESCRIPTION_TO_MECHANIC: 'Apraksts mehāniķim',
          WARRANTY: 'Garantija',
          WARRANTY_DESCRIPTION: 'Garantijas apraksts',

          WORK_ORDERER_NAME: 'Darba pasūtītāja vārds',
          ENTER_WORK_ORDERER_NAME: 'Ievadiet darba pasūtītāja vārdu',
          WORK_ORDERER_EMAIL: 'Darba pasūtītāja e-pasts',
          ENTER_WORK_ORDERER_EMAIL: 'Ievadiet darba pasūtītāja e-pastu',
          WORK_ORDERER_PHONE: 'Darba pasūtītāja tālruņa numurs',
          ENTER_WORK_ORDERER_PHONE: 'Ievadiet darba pasūtītāja tālruņa numuru',
          CREATED_BY: 'izveidojis {{user}} {{date}} no uzņēmuma {{company}}',
          add_work_to_queue: 'Pievienot darba rindai',
          Continue: 'Atveriet apkopes sarakstu',
          Repairing: 'Remonts'

        },
      },
    },
    lng: defaultLanguage, // if you're using a language detector, do not define the lng option
    fallbackLng: 'EN',

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

// append app to dom
export default i18n;
